import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";

import packageJson from "../package.json";
import App from "./App";
import "./index.css";

import Alert from "components/atoms/alert/Alert";
import { LoadingProvider } from "components/atoms/loading/useLoading";
import { ToastProvider } from "components/atoms/toast/ToastContext";

import { Worker } from "utils/rpv/core";

import { GoogleOAuthProvider } from "@react-oauth/google";
import "bootstrap/dist/css/bootstrap.min.css";

const pdfjsVersion = packageJson.dependencies["pdfjs-dist"];

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            onError: (error) => {
                if (!error.response) {
                    window.location = "/error";
                    return;
                } else if (error.response.status === 401) {
                    localStorage.removeItem("email");
                    localStorage.removeItem("token");
                    Alert("warn", "에러", "로그인이 필요합니다.", () => {
                        window.location = "/login";
                    });
                    return;
                } else if (error.response.status === 403) {
                    Alert("warn", "에러", "권한이 없습니다.", () => {
                        window.location = "/";
                    });
                    return;
                } else if (error.response.status === 404) {
                    Alert("warn", "에러", "비정상적인 접근입니다.", () => {
                        window.location = "/error";
                    });
                    return;
                } else if (error.response.status === 500) {
                    Alert("warn", "에러", "서버에 문제가 있습니다.", () => {
                        window.location = "/error";
                    });
                    return;
                }
            },
            retry: 0,
            useErrorBoundary: false,
        },
        mutations: {
            useErrorBoundary: false,
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <QueryClientProvider client={queryClient}>
        <HelmetProvider>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_SOCIAL_AUTH_GOOGLE_CLIENT_ID}>
                <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}>
                    <LoadingProvider>
                        <ToastProvider>
                            <Router>
                                <App />
                            </Router>
                        </ToastProvider>
                    </LoadingProvider>
                </Worker>
            </GoogleOAuthProvider>
        </HelmetProvider>
    </QueryClientProvider>,
);

if (process.env.REACT_APP_ENV === "prd") {
    const jennifer_front_script_el_1 = document.createElement("script");
    jennifer_front_script_el_1.innerHTML = `
        (function(j,ennifer) {
            j['dmndata']=[];j['jenniferFront']=function(args){window.dmndata.push(args)};
            j['dmnaid']=ennifer;j['dmnatime']=new Date();j['dmnanocookie']=false;j['dmnajennifer']='JENNIFER_FRONT@INTG';
        }(window, '2e052c86'));
    `;
    jennifer_front_script_el_1.async = false;

    const jennifer_front_script_el_2 = document.createElement("script");
    jennifer_front_script_el_2.src = "https://d-collect.jennifersoft.com/2e052c86/demian.js";
    jennifer_front_script_el_2.async = true;

    document.body.appendChild(jennifer_front_script_el_1);
    document.body.appendChild(jennifer_front_script_el_2);
}
