import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";

import styled from "styled-components";

import Icon from "components/atoms/Icon";
import SelectBox from "components/atoms/SelectBox";
import Alert from "components/atoms/alert/Alert";
import Button from "components/atoms/button/Button";
import useLoading from "components/atoms/loading/useLoading";
import GeneralText from "components/atoms/text/GeneralText";

import RightSideBar from "components/molecules/RightSideBar";

import { useSelectBoxOptions } from "hooks/queries/useOptions";
import { useResearchWidthHtmlCreate } from "hooks/queries/useResearch";

import { getConvertedPaper } from "utils/request/paper";
import { toBibJson } from "utils/scienceon";
import { sseUrl } from "utils/urls";

import { v4 as uuidv4 } from "uuid";

const SubHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    width: 100%;
    flex-shrink: 0;
`;

const HeaderLeftSection = styled.div`
    display: flex;
    align-items: center;
`;

const SelectBoxSection = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 8px;
`;

const MainHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px 8px 16px;
    width: 100%;
    border-bottom: 1px solid var(--color-Line);
    flex-shrink: 0;
`;

const SummarySection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
`;

const SummaryInstruction = styled.p`
    font-size: 14px;
    color: var(--color-Black);
    margin-bottom: 16px;
    text-align: center;
`;

const SummaryControls = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-top: 12px;
`;

const ItemWrapper = styled.div`
    flex: 1;
    overflow-y: scroll;
`;

const TabButtonWrap = styled.div`
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    background-color: var(--color-White);
    z-index: 1;
`;

const TabButton = styled.button`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 40px;
    background-color: var(--color-White);
    border: transparent;
    outline: none;

    &:hover {
        p {
            color: var(--color-Key);
        }
    }
`;

const TabLine = styled.span`
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${(props) => (props.isActive ? "var(--color-Key)" : "var(--color-DisabledInput)")};
`;

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: var(--color-Line);
    margin: 16px 0;
`;

function SummarySidebar(props) {
    const researchWithHtmlCreateMutation = useResearchWidthHtmlCreate();
    const optionsQuery = useSelectBoxOptions();

    const [tabActive, setTabActive] = useState("summary");
    const [language, setLanguage] = useState("ko");
    const [languageOptions, setLanguageOptions] = useState([]);
    const [styledText, setStyledText] = useState([]);
    const [summaryText, setSummaryText] = useState("");

    useEffect(() => {
        if (optionsQuery.data) {
            setLanguageOptions(optionsQuery.data.env_ai_language);
        }
    }, [optionsQuery.data]);

    useEffect(() => {
        if (props.papers.type === "searchPapers" && props.papers.list.length > 0) {
            convertToBibText(props.papers.list);
        } else if (props.papers.type === "references" && props.papers.list.length > 0) {
            setStyledText(
                props.papers.list.map((reference) => ({
                    citation_key: reference.bib_json.citation_key,
                    styled_text: reference.styled_text[0],
                })),
            );
        }
    }, [props.papers]);

    const convertToBibText = (papers) => {
        const references = papers.map((paper) => {
            return toBibJson(paper);
        });

        getConvertedPaper(references).then((res) => setStyledText(res));
    };

    const addResearch = () => {
        let research = { html_content: summaryText };
        if (props.papers.type === "searchPapers") {
            research["reference_bib_jsons"] = props.papers.list.map((paper) => toBibJson(paper));
        } else {
            research["references"] = props.papers.list;
        }
        researchWithHtmlCreateMutation.mutate(research);
    };

    //region: 논문 요약 SSE 처리
    const [sseRequestId, setSseRequestId] = useState(null);

    const queryClient = useQueryClient();
    const ssePaperSummarizeQueryKey = "ssePaperSummarize";

    const ssePaperSummarizeQuery = useQuery({
        queryKey: [ssePaperSummarizeQueryKey],
        queryFn: async ({ signal }) => {
            try {
                setLoading(true);

                const request_id = uuidv4();
                setSseRequestId(request_id);

                const response = await fetch(sseUrl(ssePaperSummarizeQueryKey), {
                    signal,
                    method: "POST",
                    headers: {
                        Authorization: `Token ${localStorage.getItem("token")}`,
                        "Content-Type": "application/json",
                        "X-Request-ID": request_id,
                    },
                    body: JSON.stringify({
                        papers:
                            props.papers.type === "searchPapers"
                                ? props.papers.list.map((paper) => ({
                                      title: paper?.title,
                                      abstract: paper?.abstract,
                                      year: paper?.year,
                                  }))
                                : props.papers.list.map((paper) => ({
                                      title: paper?.bib_json?.title,
                                      abstract: paper?.bib_json?.abstract,
                                      year: paper?.bib_json?.year,
                                  })),
                        language: language,
                    }),
                });
                setLoading(false);

                if (!response.ok) {
                    throw new Error("Stream connection failed");
                }

                const reader = response.body?.getReader();
                if (!reader) {
                    throw new Error("Stream reader not available");
                }

                const decoder = new TextDecoder();
                while (true) {
                    const { value, done } = await reader.read();

                    if (done) {
                        break;
                    }

                    const dataRecv = decoder
                        .decode(value, { stream: true })
                        .replace("[", `<span style="font-weight: 700;">[`)
                        .replace("]", `]</span>`);
                    setSummaryText((prev) => {
                        return prev + dataRecv;
                    });
                }
            } catch (error) {
                throw error;
            }
        },
        enabled: false,
        retry: false,
        onError: (error) => {
            console.log("error", error);
            Alert("warn", "에러", "요약문을 생성하지 못했습니다.");
        },
    });

    const startSummary = () => {
        setSummaryText("");
        ssePaperSummarizeQuery.refetch();
    };

    const stopSummary = async () => {
        queryClient.cancelQueries({ queryKey: [ssePaperSummarizeQueryKey] });
        await fetch(sseUrl(ssePaperSummarizeQueryKey), {
            method: "PUT",
            headers: {
                Authorization: `Token ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
                "X-Request-ID": sseRequestId,
            },
            body: JSON.stringify({}),
        });
    };

    //region: 로딩 화면 처리
    const { setLoading } = useLoading();
    useEffect(() => {
        setLoading(optionsQuery.isFetching);
    }, [optionsQuery.isFetching]);

    return (
        <RightSideBar right={"0"} onClose={() => props.exit()} top={"56px"}>
            <MainHeader>
                <HeaderLeftSection>
                    <Button
                        onlyIcon
                        bgColor={"transparent"}
                        hoverBgColor={"var(--color-ButtonHover4)"}
                        onClick={() => props.exit()}
                    >
                        <Icon name={"x"} />
                    </Button>
                    <GeneralText size={"large"} color={"var(--color-Black)"} margin={"0 0 0 8px"}>
                        요약하기
                    </GeneralText>
                </HeaderLeftSection>
            </MainHeader>
            <TabButtonWrap>
                <TabButton onClick={() => setTabActive("summary")}>
                    <GeneralText
                        size={"regular"}
                        fontWeight={"500"}
                        color={tabActive === "summary" ? "var(--color-Key)" : "var(--color-DisabledText)"}
                    >
                        요약
                    </GeneralText>
                    <TabLine isActive={tabActive === "summary"} />
                </TabButton>
                <TabButton onClick={() => setTabActive("papers")}>
                    <GeneralText
                        size={"regular"}
                        fontWeight={"500"}
                        color={tabActive === "papers" ? "var(--color-Key)" : "var(--color-DisabledText)"}
                    >
                        출처
                    </GeneralText>
                    <TabLine isActive={tabActive === "papers"} />
                </TabButton>
            </TabButtonWrap>
            {tabActive === "summary" ? (
                summaryText ? (
                    <>
                        <SubHeader>
                            <HeaderLeftSection />
                            <SelectBoxSection>
                                <SelectBox
                                    width="calc(100% - 28px)"
                                    maxWidth="120px"
                                    optionWidth="100%"
                                    optionMinWidth="120px"
                                    topBottom="top"
                                    reverse="180deg"
                                    margin={"0 4px 0 0"}
                                    value={language}
                                    items={languageOptions}
                                    onChange={(value) => setLanguage(value)}
                                />
                                {ssePaperSummarizeQuery.isFetching ? (
                                    <Button
                                        width={"130px"}
                                        height={"32px"}
                                        bgColor={"var(--color-Button2)"}
                                        hoverBgColor={"var(--color-ButtonHover2)"}
                                        fontColor={"var(--color-White)"}
                                        buttonText={"작성 중지"}
                                        margin={"0 4px 0 0"}
                                        onClick={() => {
                                            stopSummary();
                                        }}
                                    >
                                        <Icon name={"stop"} color={"var(--color-White)"} />
                                    </Button>
                                ) : (
                                    <>
                                        <Button
                                            onlyText
                                            height={"32px"}
                                            width={"100px"}
                                            bgColor={"var(--color-Button2)"}
                                            hoverBgColor={"var(--color-ButtonHover2)"}
                                            buttonText={"다시 작성"}
                                            margin={"0 4px 0 0"}
                                            onClick={() => {
                                                startSummary();
                                            }}
                                        />
                                        <Button
                                            onlyText
                                            height={"32px"}
                                            width={"100px"}
                                            bgColor={"var(--color-Button2)"}
                                            hoverBgColor={"var(--color-ButtonHover2)"}
                                            buttonText={"리서치 추가"}
                                            margin={"0 4px 0 0"}
                                            onClick={() => {
                                                addResearch();
                                            }}
                                        />
                                    </>
                                )}
                            </SelectBoxSection>
                        </SubHeader>
                        <ItemWrapper>
                            <GeneralText
                                margin={"0 12px"}
                                size={"regular"}
                                fontWeight={"400"}
                                textAlign={"left"}
                                dangerouslySetInnerHTML={{ __html: summaryText }}
                                whiteSpace={"pre-wrap"}
                            ></GeneralText>
                        </ItemWrapper>
                    </>
                ) : (
                    <>
                        <SummarySection>
                            <SummaryInstruction>요약을 작성할 언어를 선택해주세요.</SummaryInstruction>
                            <SummaryControls>
                                <SelectBox
                                    width="120px"
                                    maxWidth="120px"
                                    optionWidth="100%"
                                    optionMinWidth="120px"
                                    topBottom="top"
                                    reverse="180deg"
                                    value={language}
                                    items={languageOptions}
                                    onChange={(value) => setLanguage(value)}
                                />
                                <Button
                                    onlyText
                                    height={"32px"}
                                    width={"100px"}
                                    bgColor={"var(--color-Button2)"}
                                    hoverBgColor={"var(--color-ButtonHover2)"}
                                    buttonText={"요약하기"}
                                    onClick={() => {
                                        startSummary();
                                    }}
                                />
                            </SummaryControls>
                        </SummarySection>
                    </>
                )
            ) : (
                <div>
                    <ItemWrapper>
                        {styledText.map((text) => (
                            <React.Fragment key={text.citation_key}>
                                <GeneralText
                                    margin={"16px 12px"}
                                    size={"regular"}
                                    fontWeight={"400"}
                                    textAlign={"left"}
                                >
                                    {text.styled_text}
                                </GeneralText>
                                <Divider />
                            </React.Fragment>
                        ))}
                    </ItemWrapper>
                </div>
            )}
        </RightSideBar>
    );
}

export default SummarySidebar;
