import constants from "utils/constants";

export const emailValidation = (email) => {
    if (email) {
        let regex = /^[A-Za-z0-9_.-]+@[A-Za-z0-9-]+\.[A-Za-z0-9-]+$/;
        if (!regex.test(email)) {
            return false;
        }
    } else {
        return false;
    }

    return true;
};

export const isGroupPlanUser = (user) => {
    if (user.plan_id !== constants.FREE_PLAN && user.plan_id !== constants.PAID_PLAN && user.plan_id !== undefined) {
        return true;
    }
};
