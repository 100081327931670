import React from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";

import AbstractModalFooter from "./AbstractModalFooter";

import Icon from "components/atoms/Icon";
import Tooltip from "components/atoms/Tooltip";
import Button from "components/atoms/button/Button";
import GeneralText from "components/atoms/text/GeneralText";
import TitleText from "components/atoms/text/TitleText";

const Container = styled.div`
    width: 100%;
`;

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: var(--color-Line);
    margin: 12px 0;
`;

const PaperInfoWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const AbstractWrap = styled.div`
    display: flex;
    flex-direction: column;
    margin: 8px 0;
    max-height: 200px;
    overflow: scroll;
    text-align: justify;
`;

const ButtonWrap = styled.div`
    display: flex;
    gap: 8px;
    margin-top: 12px;
`;

const DOIWrap = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;

const LinkButtonStyle = {
    height: "18px",
    padding: "0px 4px",
    borderRadius: "4px",
    bgColor: "var(--color-Button3)",
    hoverBgColor: "var(--color-ButtonHover3)",
    fontSize: "xsmall",
};

function PaperDetailModal(props) {
    const { exitModal, paper, addReferences } = props;

    return (
        <Container>
            <PaperInfoWrap>
                <TitleText size={"subRegular"}>{paper.title}</TitleText>
                <GeneralText size={"small"}>{paper.author}</GeneralText>
                <GeneralText size={"small"}>
                    {paper.journal ? paper.journal : paper.publisher}({paper.year})
                </GeneralText>
                <ButtonWrap>
                    <Icon name={"link"} size={"12"} color={"var(--color-Grey2)"} />
                    <DOIWrap>
                        {paper.doi && (
                            <Link to={paper.doi} target={"_blank"}>
                                <Button onlyText {...LinkButtonStyle} buttonText={"DOI"} />
                            </Link>
                        )}
                        <Link
                            to={"https://scholar.google.com/scholar?q=" + encodeURIComponent(paper?.title)}
                            target={"_blank"}
                        >
                            <Button onlyText {...LinkButtonStyle} buttonText={"Google"} />
                        </Link>
                        {paper?.scienceon_content_url && (
                            <Link to={paper.scienceon_content_url} target={"_blank"}>
                                <Button onlyText {...LinkButtonStyle} buttonText={"ScienceON"} />
                            </Link>
                        )}
                        {paper?.scienceon_fulltext_url && (
                            <Link to={paper.scienceon_fulltext_url} target={"_blank"}>
                                <Button onlyText {...LinkButtonStyle} buttonText={"PDF"} />
                            </Link>
                        )}
                    </DOIWrap>
                    <div style={{ marginLeft: "auto" }}>
                        <Tooltip message={"내 참고문헌에 추가"}>
                            <Button
                                onlyIcon
                                height={"24px"}
                                bgColor={"var(--color-Button2)"}
                                hoverBgColor={"var(--color-ButtonHover2)"}
                                fontColor={"var(--color-White)"}
                                margin={"0 0 0 auto"}
                                onClick={() => {
                                    addReferences([paper]);
                                }}
                            >
                                <Icon name={"plus"} color={"var(--color-White)"} />
                            </Button>
                        </Tooltip>
                    </div>
                </ButtonWrap>
            </PaperInfoWrap>
            {paper?.abstract && (
                <>
                    <Divider />
                    <TitleText size={"subSmall"}>Abstract</TitleText>
                    <AbstractWrap>
                        <GeneralText size={"small"} margin={"4px 0 0 0"}>
                            {paper.abstract}
                        </GeneralText>
                    </AbstractWrap>
                </>
            )}
            <AbstractModalFooter onlyLeftBtn leftBtnText={"닫기"} leftOnClick={exitModal} />
        </Container>
    );
}

export default PaperDetailModal;
