import React from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import HtmlHead from "components/atoms/HtmlHead";
import Button from "components/atoms/button/Button";
import GeneralText from "components/atoms/text/GeneralText";
import TitleText from "components/atoms/text/TitleText";

import GuestUserLayout from "components/templates/GuestUserLayout";

const Container = styled.div`
    position: relative;
    top: -40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 56px);
    background-color: var(--color-White);

    > a {
        text-decoration: none;
    }
`;

const ContactLink = styled.a`
    font-weight: 600;
    color: var(--color-Key);
    text-decoration: underline !important;
    cursor: pointer;
`;

function ErrorPage(props) {
    const navigate = useNavigate();

    return (
        <GuestUserLayout noHeader>
            <HtmlHead title={"문제 발생"} />
            <Container>
                <TitleText size={"xlarge"} color={"var(--color-Key)"} textAlign={"center"}>
                    죄송합니다.
                    <br />
                    서비스에 문제가 발생했습니다.
                </TitleText>
                <GeneralText size={"large"} margin={"24px 0 0 0"} textAlign={"center"}>
                    일시적인 문제로 인해 서비스 이용에 불편을 드려 죄송합니다. 잠시후에 다시 시도해주세요.
                    <br />
                    문제가 계속되면 <ContactLink href={"mailto:prep@datansoft.com"}>문의</ContactLink>해주시기 바랍니다.
                </GeneralText>
                <Button
                    onlyText
                    margin={"40px 0 0 0"}
                    width={"280px"}
                    height={"48px"}
                    bgColor={"var(--color-Button2)"}
                    hoverBgColor={"var(--color-ButtonHover2)"}
                    fontColor={"var(--color-White)"}
                    buttonText={"이전 화면으로 이동"}
                    onClick={(e) => {
                        navigate(-1);
                    }}
                />
            </Container>
        </GuestUserLayout>
    );
}

export default ErrorPage;
