import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";

import styled from "styled-components";

import Icon from "components/atoms/Icon";
import Alert from "components/atoms/alert/Alert";
import Button from "components/atoms/button/Button";
import GeneralText from "components/atoms/text/GeneralText";

import { useUser } from "hooks/queries/useUser";

import { getDefaultBibtex } from "utils/bibtex";
import Constants from "utils/constants";
import { postBibliography } from "utils/request/bibliography";
import { apiUrl } from "utils/urls";

import axios from "axios";

const Container = styled.div`
    width: 100%;
`;

const ContentsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 24px 0;
    width: 100%;
    background-color: transparent;
    max-height: 512px;
    overflow-y: auto;
`;

const ItemRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 4px 0;
    padding: 12px;
    width: 100%;
    height: 60px;
    border-radius: 4px;
    background-color: ${(props) =>
        props.bgColor === "완료" ? "var(--color-Base2)" : props.bgColor === "오류" ? "#F5F3F2" : "#f2f5f5"};
`;

const PDFIconWrap = styled.div`
    margin: 0 12px 0 0;
    width: 36px;
    min-width: 36px;
    height: 36px;
    background-color: transparent;
`;

const CenterWrap = styled.div`
    flex: 1;
    display: flex;
    max-width: calc(100% - 84px);
    flex-direction: column;
`;

const StatusWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 12px;
    width: 24px;
    min-width: 24px;
    height: 24px;
`;

const ErrorWrap = styled.div`
    display: flex;
    align-items: center;
`;

const FileCapacityWrap = styled.div`
    display: flex;
    align-items: center;
`;

const DeleteButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background-color: transparent;
    border: transparent;
    outline: none;
`;

const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 16px 0 0 0;
    width: 100%;
    height: 32px;
`;

var UPLOADING_FILES = {}; // 비동기 처리 중 데이터 일관성 유지를 위한 임시 객체

function ReferenceFileUploadModal(props) {
    const userQuery = useUser();
    const [user, setUser] = useState(null);
    useEffect(() => {
        if (userQuery.data) {
            setUser(userQuery.data);
        }
    }, [userQuery.data]);

    const WAIT = 1;
    const WORK = 2;
    const DONE = 3;

    const [taskStatus, setTaskStatus] = useState(WAIT);
    const [uploadingFiles, setUploadingFiles] = useState({});
    const [hoverIndex, setHoverIndex] = useState(null);

    const [workingFilename, setWorkingFilename] = useState(null);

    useEffect(() => {
        if (props.uploadingFiles) {
            const files = {};
            props.uploadingFiles.map((file) => {
                files[file.name] = {
                    file: file,
                    status: "대기",
                    uploadedSize: 0, //파일 업로드 전 0KB 표기
                };
            });
            UPLOADING_FILES = files;
            setUploadingFiles(UPLOADING_FILES);

            setTaskStatus(WORK);
            uploadNextFile();
        }

        return () => {
            UPLOADING_FILES = {};
            setUploadingFiles(UPLOADING_FILES);

            setTaskStatus(WAIT);
        };
    }, []);

    useEffect(() => {
        if (taskStatus === DONE) {
            // console.log("all files uploaded.");
        }
    }, [taskStatus]);

    const uploadNextFile = () => {
        let found = false;
        for (const [filename, item] of Object.entries(UPLOADING_FILES)) {
            if (item["status"] === "대기") {
                found = true;
                setWorkingFilename(filename);
                break;
            }
        }
        if (!found) {
            setWorkingFilename(null);
            setTaskStatus(DONE);
        }
    };

    useEffect(() => {
        if (workingFilename) {
            let item = UPLOADING_FILES[workingFilename];

            let error_message = checkFileUploadable(item.file);
            let uploadable = error_message.length === 0;
            item = {
                ...item,
                status: uploadable ? "업로드" : "오류",
                error_message: error_message,
            };
            UPLOADING_FILES[workingFilename] = item;
            setUploadingFiles({ ...UPLOADING_FILES });

            if (uploadable) {
                createReferenceWithFile(workingFilename, item);
            } else {
                uploadNextFile();
            }
        }
    }, [workingFilename]);

    const checkFileUploadable = (file) => {
        if (!file.name.toLowerCase().endsWith(".pdf")) {
            let error_message = "PDF 파일만 업로드 가능합니다.";
            return error_message;
        }

        if (file.size > 50 * Constants.MB) {
            let error_message = "50MB 이상의 파일은 업로드할 수 없습니다.";
            return error_message;
        }

        if (
            (user.plan_id === Constants.FREE_PLAN && user.file_total_size + file.size > 200 * Constants.MB) ||
            (user.plan_id !== Constants.FREE_PLAN && user.file_total_size + file.size > 20 * Constants.GB)
        ) {
            let error_message = "저장소 용량이 초과되었습니다.";
            return error_message;
        }

        return "";
    };

    const createReferenceWithFile = (filename, item) => {
        let bibtex = getDefaultBibtex(filename);
        bibtex["file"] = item.file;
        bibliographyMutation.mutate({ body: bibtex });
    };

    // 참고 문헌 추가 mutation
    const bibliographyMutation = useMutation(postBibliography, {
        onSuccess: (data, variables, context) => {
            let upload_data = { file: variables.body.file, reference_id: data.id };
            uploadFile(upload_data);
        },
        onError: (error, variables, context) => {
            if (error.response.status === 400) {
                if (error.response.data.bib_json) {
                    if (error.response.data.bib_json.citation_key) {
                        Alert("warn", "에러", error.response.data.bib_json.citation_key);
                    } else {
                        Alert("warn", "에러", error.response.data.bib_json);
                    }
                } else {
                    Alert("warn", "에러", "참고문헌 추가에 실패했습니다.");
                }
            } else {
                Alert("warn", "에러", "서버에 오류가 발생했습니다.");
            }
        },
    });

    const uploadFile = (upload_data) => {
        axios
            .post(apiUrl("bibliography") + "/file", upload_data, {
                headers: { "Content-Type": "multipart/form-data" },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    const uploadedSize = progressEvent.loaded;
                    updateFileUploadStatus(workingFilename, { status: `${percentCompleted}%`, uploadedSize });
                    if (percentCompleted === 100) {
                        updateFileUploadStatus(workingFilename, { status: "검증" });
                    }
                },
            })
            .then((res) => {
                updateFileUploadStatus(workingFilename, {
                    status: "완료",
                    uploadedSize: UPLOADING_FILES[workingFilename].file.size,
                });
                uploadNextFile();
            })
            .catch((error) => {
                console.log(error);
                updateFileUploadStatus(workingFilename, { status: "오류", error_message: "검증실패" });
                uploadNextFile();
            });
    };

    const cancelFile = (item) => {
        updateFileUploadStatus(item.file.name, { status: "오류", error_message: "취소했습니다." });
    };

    const updateFileUploadStatus = (filename, content) => {
        let item = {
            ...UPLOADING_FILES[filename],
            ...content,
        };
        UPLOADING_FILES[filename] = item;
        setUploadingFiles({ ...UPLOADING_FILES });
    };

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 KB";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }; // 용량 표기

    return (
        <Container>
            {taskStatus === DONE ? (
                <GeneralText size={"regular"}>파일 업로드를 완료했습니다.</GeneralText>
            ) : (
                <GeneralText size={"regular"}>파일 업로드 중입니다.</GeneralText>
            )}
            <ContentsWrapper>
                {Object.values(uploadingFiles).map((item, index) => (
                    <ItemRow
                        key={index}
                        bgColor={item.status === "완료" ? "완료" : item.status === "오류" ? "오류" : "대기"} //업로드 상태에 따른 아이템 배경 색상 변화
                    >
                        <PDFIconWrap>
                            <Icon
                                name={"fileUpload"}
                                size={"36px"}
                                color={item.status === "완료" ? "var(--color-Key)" : "#BFC8C6"}
                                //업로드 상태에 따른 PDF 아이콘 색상 변화
                            />
                        </PDFIconWrap>
                        <CenterWrap>
                            <GeneralText
                                margin={"0 0 4px 0"}
                                size={"regular"}
                                color={item.status === "완료" ? "var(--color-Black)" : "var(--color-Grey1)"}
                                ellipsis
                            >
                                {item.file.name}
                            </GeneralText>
                            {item.status === "오류" ? (
                                <ErrorWrap>
                                    <GeneralText size={"xsmall"} color={"var(--color-WarnHover)"}>
                                        {item.error_message}
                                    </GeneralText>
                                </ErrorWrap>
                            ) : (
                                <FileCapacityWrap>
                                    <GeneralText size={"xsmall"} color={"var(--color-Grey1)"}>
                                        {/* 파일의 업로드 된 용량 */}
                                        {formatBytes(item.uploadedSize)}
                                    </GeneralText>
                                    <GeneralText margin={"0 4px"} size={"xsmall"} color={"var(--color-Grey1)"}>
                                        /
                                    </GeneralText>
                                    <GeneralText size={"xsmall"} color={"var(--color-Grey1)"}>
                                        {/* 파일의 전체 용량 */}
                                        {formatBytes(item.file.size)}
                                    </GeneralText>
                                </FileCapacityWrap>
                                // 파일 업로드 용량 표기
                            )}
                        </CenterWrap>
                        <StatusWrap>
                            {item.status === "완료" ? (
                                <Icon name={"check"} size={"14"} color={"var(--color-Key)"} />
                            ) : item.status === "오류" ? (
                                <Icon name={"alert"} size={"16"} color={"var(--color-WarnHover)"} />
                            ) : item.status.includes("%") || item.status === "검증" ? (
                                <Icon name={"load"} size={"16px"} />
                            ) : (
                                <DeleteButton
                                    onMouseEnter={() => setHoverIndex(index)}
                                    onMouseLeave={() => setHoverIndex(null)}
                                    onClick={() => cancelFile(item)}
                                >
                                    <Icon name={hoverIndex === index ? "cancelHover" : "cancel"} size={"14px"} />
                                </DeleteButton>
                            )}
                            {/* 파일 업로드 상태에 따른 아이콘 변화 */}
                        </StatusWrap>
                    </ItemRow>
                ))}
            </ContentsWrapper>
            <Footer>
                <Button
                    onlyText
                    margin={"0 0 0 4px"}
                    width={"80px"}
                    height={"32px"}
                    bgColor={"var(--color-Button1)"}
                    hoverBgColor={"var(--color-ButtonHover1)"}
                    fontColor={"var(--color-White)"}
                    buttonText={"닫기"}
                    disabled={taskStatus !== DONE}
                    onClick={props.exitModal}
                />
            </Footer>
        </Container>
    );
}

export default ReferenceFileUploadModal;
