import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill-new";
import "react-quill-new/node_modules/quill/dist/quill.snow.css";
import { useReactToPrint } from "react-to-print";

import styled from "styled-components";

import Alert from "components/atoms/alert/Alert";
import AIResponseComponent from "components/atoms/block/AIResponse";
import useLoading from "components/atoms/loading/useLoading";
import GeneralText from "components/atoms/text/GeneralText";

import ContextMenu from "components/molecules/ContextMenu";
import ResearchEditorToolbar, { formats, modules } from "components/organisms/research/ResearchEditorToolbar";

import {
    useReferenceCitationDelete,
    useResearchMedia,
    useResearchMediaBulkDelete,
    useResearchMediaCreate,
} from "hooks/queries/useResearch";

import { wsUrl } from "utils/urls";
import { uuidv4 } from "utils/uuid";

import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize";

const QuillStyledContainer = styled.div`
    .ql-container.ql-snow {
        justify-content: center;
    }

    .ql-editor.ql-blank::before {
        left: auto;
        right: auto;
    }

    .ql-editor-reference-blot {
        border-radius: 4px;
        cursor: default;
        margin: 2px;
        padding: 1px 2px;
        color: var(--color-SubBlack);
        background-color: var(--color-QuoteBox);
    }
`;

const EditorContainer = styled(QuillStyledContainer)`
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;

    .ql-container {
        display: flex;
        flex-direction: column;
        overflow: visible;
        min-height: calc(100vh - 240px);
    }

    .ql-editor {
        flex: 1;
        width: 70%;
        max-width: 800px;
        background-color: white;
        border: 1px solid #d1d1d1;
        padding: 60px;
        margin: 20px auto;
        position: relative;
    }

    .ql-toolbar.ql-snow {
        text-align: center;
        background-color: white;
        position: sticky;
        top: 48px;
        z-index: 99;
        width: 100%;
    }
`;

const PrintContainer = styled.div`
    display: none;
`;

const PrintContentContainer = styled.div`
    max-width: 800px;

    padding: 60px;
    margin: 0px auto;
`;

const PrintReferenceContainer = styled.div`
    max-width: 800px;

    padding: 0px 60px;
    margin: 0px auto;
`;

const PrintReferenceTitle = styled.h2`
    margin-bottom: 10px;
`;
const PrintReferenceContent = styled.div``;

const Inline = Quill.import("blots/embed");

class ReferenceBlot extends Inline {
    static blotName = "reference";
    static tagName = "span";
    static className = "ql-editor-reference-blot";

    static create(value) {
        let node = super.create();
        node.setAttribute("data-reference", value.uuid);
        node.setAttribute("data-id", value.id);
        node.setAttribute("data-front-uuid", value.frontUuid);
        node.classList.add("ql-editor-reference-blot");
        node.setAttribute("contenteditable", "false");
        return node;
    }

    static value(node) {
        return {
            uuid: node.getAttribute("data-reference"),
            id: node.getAttribute("data-id"),
            frontUuid: node.getAttribute("data-front-uuid"),
        };
    }
}
Quill.register(ReferenceBlot);

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/ImageResize", ImageResize);

function ResearchEditor(props, ref) {
    const [showContextMenu, setShowContextMenu] = useState({
        show: false,
        x: 0,
        y: 0,
    });
    const [operations, setOperations] = useState([]);

    const [aiResponse, setAiResponse] = useState({ type: "", text: "" });
    const [aiResponseProcessing, setAiResponseProcessing] = useState(false);
    const [researchMedia, setResearchMedia] = useState([]);

    const deleteReferenceCitationMutation = useReferenceCitationDelete();
    const createResearchMediaMutation = useResearchMediaCreate();
    const deleteResearchMediaMutation = useResearchMediaBulkDelete();
    const researchMediaQuery = useResearchMedia({ research: props.research.id });

    const { setLoading } = useLoading();
    const wsRef = useRef(null);
    const quillRef = useRef();

    useEffect(() => {
        if (researchMediaQuery.data) {
            setResearchMedia(researchMediaQuery.data);
        }
    }, [researchMediaQuery.data]);

    const updateCitationPhrases = () => {
        const blotElements = document.querySelectorAll(".ql-editor-reference-blot");
        blotElements.forEach((element) => {
            const text = props.research?.cited_references?.find(
                (reference) => reference.uuid === element.getAttribute("data-reference"),
            )?.citation_phrase;
            element.innerText = text;
        });
    };

    useEffect(() => {
        if (props.research?.cited_references) {
            updateCitationPhrases();
        }
    }, [props.research?.cited_references, props.value]);

    const addReference = (uuid) => {
        // 인용 시 참고문헌 추가
        const quill = quillRef.current.getEditor();
        quill.focus();
        const selection = quill.getSelection();

        if (selection && selection.index !== null) {
            const cursorPosition = selection.index;

            quill.insertEmbed(
                cursorPosition + selection.length,
                "reference",
                { uuid: uuid, id: "undefined", frontUuid: uuidv4() },
                "user",
            );
            quill.setSelection(cursorPosition + selection.length + 1, 0, "user");
        }

        setShowContextMenu({ ...showContextMenu, show: false });
    };

    const deleteReference = (uuid) => {
        // uuid에 해당하는 참고문헌 블롯 모두 삭제
        const blotElements = document.querySelectorAll(".ql-editor-reference-blot");
        blotElements.forEach((element) => {
            if (element.getAttribute("data-reference") === uuid) {
                element.remove();
            }
        });
    };

    useImperativeHandle(ref, () => ({
        addReference,
        deleteReference,
        getEditor: () => quillRef.current.getEditor(),
    }));

    useEffect(() => {
        const quill = quillRef.current.getEditor();

        quill.clipboard.addMatcher("span", function (node, delta) {
            let blot = delta.ops.find((op) => op.insert && op.insert.reference);
            if (blot) {
                blot.insert.reference.frontUuid = uuidv4();
            }
            return delta;
        });
    }, []);

    const handleTextChange = (content, delta, source, editor) => {
        let currentContents = editor.getContents();
        if (source === "user") {
            const operationsReference = operations.filter((op) => op.insert && op.insert.reference);

            const currentContentsReference = currentContents.ops.filter((op) => op.insert && op.insert.reference);

            const deletedReferences = operationsReference.filter(
                (op) =>
                    !currentContentsReference.some(
                        (currentOp) => currentOp.insert.reference.id === op.insert.reference.id,
                    ),
            );

            const insertedReferences = currentContentsReference.filter(
                (op) =>
                    !operationsReference.some(
                        (currentOp) => currentOp.insert.reference.frontUuid === op.insert.reference.frontUuid,
                    ),
            );

            if (deletedReferences.length > 0) {
                deleteReferenceCitationMutation.mutate(
                    {
                        research_uuid: props.research.uuid,
                        ids: deletedReferences.map((op) => op.insert.reference.id),
                    },
                    {
                        onSuccess: () => {
                            props.updateResearchMutation.mutate({
                                id: props.research.id,
                                html_content: content,
                            });
                        },
                    },
                );
            }

            if (insertedReferences.length > 0) {
                let references = [];
                insertedReferences.forEach((op) => {
                    const { uuid, frontUuid } = op.insert.reference;
                    references.push({ uuid: uuid, front_uuid: frontUuid });
                });

                props.referenceCitationMutation.mutate(
                    {
                        research_id: props.research.id,
                        references: references,
                    },
                    {
                        onSuccess: (data, variables) => {
                            const newContents = editor.getContents();
                            data.citations.forEach((reference) => {
                                const referenceBlot = newContents.ops.find(
                                    (op) =>
                                        op.insert && op.insert.reference?.frontUuid === reference.reference_front_uuid,
                                );
                                if (referenceBlot) {
                                    referenceBlot.insert.reference.id = reference.id;
                                }
                            });
                            quillRef.current.getEditor().setContents(newContents, "api");
                            // TODO. 라이프사이클이 명확하지 않아 중복되는 코드 추후 리팩토링 필요
                            const blotElements = document.querySelectorAll(".ql-editor-reference-blot");
                            blotElements.forEach((element) => {
                                const text = data.research.cited_references?.find(
                                    (reference) => reference.uuid === element.getAttribute("data-reference"),
                                )?.citation_phrase;
                                element.innerText = text;
                            });
                            quillRef.current.getEditor().getContents();
                            props.updateResearchMutation.mutate({
                                id: props.research.id,
                                html_content: quillRef.current.getEditor().root.innerHTML,
                            });
                        },
                    },
                );
            }

            const operationsImage = operations.filter((op) => op.insert && op.insert.image);
            const currentContentsImage = currentContents.ops.filter((op) => op.insert && op.insert.image);
            const deletedImages = operationsImage.filter(
                (op) => !currentContentsImage.some((currentOp) => currentOp.insert.image === op.insert.image),
            );

            const insertedImages = currentContentsImage.filter(
                (op) => !operationsImage.some((currentOp) => currentOp.insert.image === op.insert.image),
            );

            if (deletedImages.length > 0) {
                const deleteIds = deletedImages.map((op) => {
                    const media = researchMedia.find((media) => media.file === op.insert.image);
                    return media.id;
                });

                deleteResearchMediaMutation.mutate(
                    { ids: deleteIds },
                    {
                        onSuccess: () => {
                            props.updateResearchMutation.mutate({
                                id: props.research.id,
                                html_content: content,
                            });
                        },
                    },
                );
            }

            if (insertedImages.length > 0) {
                insertedImages.forEach((op) => {
                    if (isValidBase64(op.insert.image)) {
                        const blob = base64ToBlob(op.insert.image);
                        const mimeType = blob.type;
                        const fileExtension = getFileExtension(mimeType);
                        const fileName = `${uuidv4()}.${fileExtension}`; // 고유한 파일명 생성

                        const formData = new FormData();
                        formData.append("file", blob, fileName);
                        formData.append("research", props.research.id);
                        formData.append("file_type", "image");

                        // 이미지 삽입 처리
                        createResearchMediaMutation.mutate(formData, {
                            onSuccess: (data) => {
                                const uploadedImageUrl = data.file;
                                replaceBase64ImageWithUrl(uploadedImageUrl, editor);
                            },
                        });
                    }
                });
            }
        }
        setOperations(currentContents.ops);

        props.onChange(content);
    };

    const getSelectedTextWithContext = () => {
        const quill = quillRef.current.getEditor();
        quill.focus();
        const range = quill.getSelection();

        const selectedText = quill.getText(range.index, range.length);
        const beforeText = quill.getText(Math.max(0, range.index - 50), Math.min(50, range.index));
        const afterText = quill.getText(range.index + range.length, range.index + range.length + 50);

        return { selectedText, beforeText, afterText };
    };

    const aiAction = (requestType) => {
        setLoading(true);
        const { selectedText, beforeText, afterText } = getSelectedTextWithContext();

        wsRef.current = new WebSocket(wsUrl("researchContentGenerator"));
        wsRef.current.onopen = (evt) => {
            setAiResponseProcessing(true);

            wsRef.current.send(
                JSON.stringify({
                    action: "generate_content",
                    text: {
                        selected_text: selectedText,
                        before_text: beforeText,
                        after_text: afterText,
                    },
                    request_type: requestType,
                    language: props.research?.ai_response_language,
                }),
            );
        };

        wsRef.current.onmessage = (evt) => {
            const data = JSON.parse(evt.data);
            setLoading(false);
            if (data.content === "<END>") {
                wsRef.current.close();
            } else if (data.content === "<START>") {
                setAiResponse({ type: "", text: "" });
            } else {
                setAiResponse((prev) => ({ type: requestType, text: prev.text + data.content }));
            }
        };

        wsRef.current.onerror = (error) => {
            console.log(error);
        };
        wsRef.current.onclose = (event) => {
            if (event.code !== 1000) {
                // 정상 종료가 아닌 경우
                setLoading(false);
                console.log(event);
                Alert("warn", "에러", "웹소켓 연결이 끊겼습니다.");
            }
            setAiResponseProcessing(false);
            wsRef.current = null;
        };
    };

    const aiResponseHandler = (requestType) => {
        const quill = quillRef.current.getEditor();
        quill.focus();
        const range = quill.getSelection();

        if (requestType === "overwrite") {
            quill.insertText(range.index, aiResponse.text, "user");
            quill.deleteText(range.index + aiResponse.text.length, range.length, "user");
        } else if (requestType === "add") {
            quill.insertText(range.index + range.length, "\n" + aiResponse.text, "user");
        } else {
            // do nothing
        }
        setAiResponse((prev) => ({ type: prev.type, text: "" }));
    };

    const replaceBase64ImageWithUrl = (imageUrl, editor) => {
        const contents = editor.getContents();
        const newContents = contents.ops.map((op) => {
            if (op.insert && typeof op.insert === "object" && op.insert.image && op.insert.image.startsWith("data:")) {
                return { ...op, insert: { image: imageUrl } };
            }
            return op;
        });
        quillRef.current.getEditor().setContents(newContents, "api");
    };

    const isValidBase64 = (base64) => {
        const base64Regex = /^data:image\/(png|jpeg|gif|webp|svg\+xml|bmp|tiff);base64,[A-Za-z0-9+/]+={0,2}$/;
        return base64Regex.test(base64);
    };

    const updateTimeRef = useRef(null);

    useEffect(() => {
        const updateSaveTimePosition = () => {
            if (quillRef.current) {
                const quill = quillRef.current.getEditor().root;
                if (quill) {
                    // 에디터 패딩 값을 가져옴
                    const editorStyle = window.getComputedStyle(quill);
                    const right = parseFloat(editorStyle.marginRight) + parseFloat(editorStyle.paddingRight);
                    // lastSaveTimeRef 스타일 설정
                    updateTimeRef.current.style.position = "absolute";
                    updateTimeRef.current.style.bottom = `42px`;
                    updateTimeRef.current.style.right = `${right}px`;

                    quill.parentNode.appendChild(updateTimeRef.current);
                }
            }
        };

        updateSaveTimePosition();

        // 스크롤 이벤트 리스너 추가
        window.addEventListener("resize", updateSaveTimePosition);

        // 컴포넌트 언마운트 시 스크롤 이벤트 리스너 제거
        return () => {
            window.removeEventListener("resize", updateSaveTimePosition);
        };
    }, [quillRef.current]);

    const getFileExtension = (mimeType) => {
        const mimeExtensions = {
            "image/jpeg": "jpg",
            "image/png": "png",
            "image/gif": "gif",
            "image/webp": "webp",
            "image/svg+xml": "svg",
            "image/bmp": "bmp",
            "image/tiff": "tiff",
            // 추가적인 MIME 타입과 확장자를 여기에 추가할 수 있습니다.
        };
        return mimeExtensions[mimeType] || "png"; // 기본값은 png로 설정
    };
    const base64ToBlob = (base64) => {
        try {
            const byteString = window.atob(base64.split(",")[1]);
            const mimeString = base64.split(",")[0].split(":")[1].split(";")[0];
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ab], { type: mimeString });
        } catch (error) {
            console.error("Failed to convert base64 to Blob:", error);
            return null;
        }
    };

    const deltaToHtml = (delta) => {
        const tempQuill = new Quill(document.createElement("div"));
        tempQuill.setContents(delta);
        return tempQuill.root.innerHTML;
    };

    // Esc 클릭 시 컨텍스트 메뉴 숨김
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "Escape") {
                setShowContextMenu({ show: false, x: 0, y: 0 });
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    const printRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    return (
        <EditorContainer className="editor-wrapper">
            <ResearchEditorToolbar save={props.updateResearchEditor} onPrint={handlePrint} />
            <div
                onContextMenu={(e) => {
                    console.log(e);
                    e.preventDefault();
                    setShowContextMenu({
                        show: true,
                        x: e.clientX,
                        y: e.clientY,
                    });
                }}
                onMouseUp={(e) => {
                    if (e.button === 0) {
                        const quill = quillRef.current.getEditor();
                        quill.focus();
                        const selection = quill.getSelection();

                        if (selection && selection.length > 0) {
                            setShowContextMenu({
                                show: true,
                                x: e.clientX,
                                y: e.clientY,
                            });
                        } else {
                            setShowContextMenu({ show: false, x: 0, y: 0 });
                        }
                    }
                }}
            >
                <ReactQuill
                    value={props.value}
                    onChange={handleTextChange}
                    modules={modules}
                    formats={formats}
                    placeholder="작성된 내용이 없습니다."
                    ref={quillRef}
                ></ReactQuill>
            </div>
            <div ref={updateTimeRef} className={"last-save-time"}>
                <GeneralText size={"xsmall"} color={"var(--color-Grey1)"}>
                    마지막 저장 시간:{" "}
                    {props.editorContentChangeLoading ? "저장 중 입니다." : props.research?.updated_at}
                </GeneralText>
            </div>
            <PrintContainer>
                <QuillStyledContainer className={"ql-container"} ref={printRef}>
                    <PrintContentContainer
                        className={"ql-editor"}
                        dangerouslySetInnerHTML={{ __html: quillRef.current?.getEditor().root.innerHTML }}
                    />
                    {props.research?.cited_references?.length > 0 && (
                        <PrintReferenceContainer>
                            <PrintReferenceTitle>참고문헌</PrintReferenceTitle>
                            <PrintReferenceContent>
                                {props.research.cited_references.map((reference) => {
                                    return <p key={reference.uuid}>{reference.styled_text}</p>;
                                })}
                            </PrintReferenceContent>
                        </PrintReferenceContainer>
                    )}
                </QuillStyledContainer>
            </PrintContainer>
            <ContextMenu
                show={showContextMenu.show}
                position={{ x: showContextMenu.x, y: showContextMenu.y }}
                items={[
                    {
                        name: "잘라내기",
                        iconName: "copyCut",
                        action: () => {
                            const quill = quillRef.current.getEditor();
                            quill.focus();
                            const selection = quill.getSelection();
                            if (selection && selection.length > 0) {
                                const range = quill.getSelection();
                                const contents = quill.getContents(range);
                                const html = deltaToHtml(contents);
                                navigator.clipboard
                                    .write([
                                        new ClipboardItem({
                                            "text/html": new Blob([html], { type: "text/html" }),
                                            "text/plain": new Blob([quill.getText(range)], { type: "text/plain" }),
                                        }),
                                    ])
                                    .then(() => {
                                        quill.deleteText(range.index, range.length, "user");
                                    })
                                    .catch((err) => {
                                        console.error("잘라내기 실패: ", err);
                                    });
                            }
                        },
                        disabled: quillRef?.current?.getEditor()?.getSelection()?.length === 0,
                    },
                    {
                        name: "복사",
                        iconName: "copy",
                        action: () => {
                            const quill = quillRef.current.getEditor();
                            quill.focus();
                            const selection = quill.getSelection();
                            if (selection && selection.length > 0) {
                                const range = quill.getSelection();
                                const contents = quill.getContents(range);
                                const html = deltaToHtml(contents);
                                navigator.clipboard
                                    .write([
                                        new ClipboardItem({
                                            "text/html": new Blob([html], { type: "text/html" }),
                                            "text/plain": new Blob([quill.getText(range)], { type: "text/plain" }),
                                        }),
                                    ])
                                    .then(() => {
                                        console.log("복사되었습니다.");
                                    })
                                    .catch((err) => {
                                        console.error("복사 실패: ", err);
                                    });
                            }
                        },
                        disabled: quillRef?.current?.getEditor()?.getSelection()?.length === 0,
                    },
                    {
                        name: "붙여넣기",
                        iconName: "copyWithStyle",
                        action: () => {
                            const quill = quillRef.current.getEditor();
                            quill.focus();
                            navigator.clipboard
                                .read()
                                .then((items) => {
                                    for (const item of items) {
                                        if (item.types.includes("text/html")) {
                                            item.getType("text/html")
                                                .then((blob) => blob.text())
                                                .then((html) => {
                                                    const selection = quill.getSelection();
                                                    quill.clipboard.dangerouslyPasteHTML(selection.index, html, "user");
                                                });
                                            break;
                                        } else if (item.types.includes("text/plain")) {
                                            item.getType("text/plain")
                                                .then((blob) => blob.text())
                                                .then((text) => {
                                                    const selection = quill.getSelection();
                                                    quill.insertText(selection.index, text, "user");
                                                });
                                            break;
                                        }
                                    }
                                })
                                .catch((err) => {
                                    console.error("붙여넣기 실패: ", err);
                                });
                        },
                    },
                    {
                        name: "서식없이 붙여넣기",
                        iconName: "copy",
                        action: () => {
                            const quill = quillRef.current.getEditor();
                            quill.focus();
                            navigator.clipboard
                                .readText()
                                .then((text) => {
                                    const selection = quill.getSelection();
                                    const plainText = text
                                        .split("\n")
                                        .map((line) => `<p>${line}</p>`)
                                        .join("");
                                    quill.clipboard.dangerouslyPasteHTML(selection.index, plainText);
                                })
                                .catch((err) => {
                                    console.error("붙여넣기 실패: ", err);
                                });
                        },
                    },

                    {
                        type: "divider",
                    },
                    {
                        name: "교정",
                        iconName: "botCalibration",
                        action: () => {
                            aiAction("correction");
                        },
                        disabled: quillRef?.current?.getEditor()?.getSelection()?.length === 0,
                    },
                    {
                        name: "번역",
                        iconName: "botTranslate",
                        action: () => {
                            aiAction("translation");
                        },
                        disabled: quillRef?.current?.getEditor()?.getSelection()?.length === 0,
                    },
                    {
                        name: "늘이기",
                        iconName: "botStretch",
                        action: () => {
                            aiAction("extension");
                        },
                        disabled: quillRef?.current?.getEditor()?.getSelection()?.length === 0,
                    },
                    {
                        name: "줄이기",
                        iconName: "botReduce",
                        action: () => {
                            aiAction("shortening");
                        },
                        disabled: quillRef?.current?.getEditor()?.getSelection()?.length === 0,
                    },
                    {
                        type: "divider",
                    },
                    {
                        name: "이어쓰기",
                        iconName: "botRelay",
                        action: () => {
                            aiAction("continuation");
                        },
                    },
                    {
                        type: "divider",
                    },
                    {
                        name: "인용하기",
                        iconName: "quote",
                        action: () => {
                            props.setShowReferenceSidebar(true);
                        },
                    },
                ]}
                onClose={() => setShowContextMenu({ ...showContextMenu, show: false })}
                triggerClassName={`ql-editor-context-menu-trigger`}
            />
            {(aiResponseProcessing || aiResponse.text) && (
                <AIResponseComponent
                    y={showContextMenu.y}
                    aiResponse={aiResponse.text}
                    aiResponseIng={aiResponseProcessing}
                    stopAiResponse={() => {
                        if (wsRef.current) {
                            wsRef.current.close();
                        }
                    }}
                    setAiResponse={setAiResponse}
                    aiResponseHandler={aiResponseHandler}
                    changeLanguage={(language) =>
                        props.updateResearchMutation.mutate({
                            id: props.research.id,
                            ai_response_language: language,
                        })
                    }
                    research={props.research}
                    rewrite={() => aiAction(aiResponse.type)}
                />
            )}
        </EditorContainer>
    );
}

export default forwardRef(ResearchEditor);
