import React, { useEffect, useState } from "react";
import NaverLogin from "react-naver-login";
import { useMutation } from "react-query";
import { Link, useNavigate } from "react-router-dom";

import styled, { css } from "styled-components";

import GoogleIconImage from "assets/png/others/GoogleIconImage.png";
import NaverIconImage from "assets/png/others/NaverIconImage.png";

import HtmlHead from "components/atoms/HtmlHead";
import Image from "components/atoms/Image";
import Alert from "components/atoms/alert/Alert";
import Button from "components/atoms/button/Button";
import useLoading from "components/atoms/loading/useLoading";
import GeneralText from "components/atoms/text/GeneralText";
import TitleText from "components/atoms/text/TitleText";

import FormInput from "components/molecules/FormInput";
import GuestUserSingleCardLayout from "components/templates/GuestUserSingleCardLayout";

import { encrypt } from "utils/crypto";
import { useDevice } from "utils/device";
import { loginApi } from "utils/request/auth";
import { apiUrl } from "utils/urls";

import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

const LogoColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            align-items: flex-start;
        `};
`;

const MessageWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            justify-content: flex-start;
        `};
`;

const InputWrap = styled.div`
    width: 100%;
`;

const SearchPwWrap = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const DivideWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const DivideLine = styled.div`
    flex: 1;
    height: 1px;
    border-top: solid 1px var(--color-Grey2);
`;

const ImgWrap = styled.img`
    width: 100%;
`;

const TextButtonStyle = {
    width: "fit-content",
    height: "fit-content",
    bgColor: "transparent",
    hoverBgColor: "transparent",
    fontSize: "small",
    fontColor: "var(--color-Grey1)",
    textAlign: "left",
    hoverTextStyle: "color: var(--color-Key); text-decoration: underline;",
};

function LoginPage() {
    const [email, setEmail] = useState(undefined);
    const [password, setPassword] = useState("");
    const [error, setError] = useState({});

    const { setLoading } = useLoading();

    const device = useDevice();

    const navigate = useNavigate();

    useEffect(() => {
        // 로그인 상태에서는 참고문헌 관리로 이동
        if (localStorage.getItem("token")) {
            navigate("/reference");
        }
    }, []);

    const loginMutation = useMutation(loginApi, {
        onSuccess: (data) => {
            let email = data.user_email;
            let encryptedEmail = encrypt(email);
            localStorage.setItem("email", encryptedEmail);
            localStorage.setItem("token", data.auth_token);
            window.location = "/reference";
        },
        onError: (e) => {
            if (e.response.status === 400) {
                setError({});
                const res = e.response.data;
                setError(res);
                if (Object.keys(res).includes("non_field_errors")) {
                    setError({
                        ...error,
                        email: res.non_field_errors,
                        password: res.non_field_errors,
                    });
                }
            } else {
                Alert("warn", "에러", "서버에 오류가 발생하였습니다.");
            }
        },
    });

    const login = () => {
        loginMutation.mutate({ email: email, password: password });
    };

    const handleInputKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            login();
        }
    };

    const googleSocialLogin = useGoogleLogin({
        clientId: process.env.REACT_APP_SOCIAL_AUTH_GOOGLE_CLIENT_ID,
        scope: "email profile",
        onSuccess: async (data) => {
            setLoading(true);
            axios.post(apiUrl("googleLogin"), { code: data.code }).then((data) => {
                let email = data.data.user_email;
                let encryptedEmail = encrypt(email);
                localStorage.setItem("email", encryptedEmail);
                localStorage.setItem("token", data.data.auth_token);
                window.location = "/reference";
                setLoading(false);
            });
        },
        onError: (e) => {
            Alert("warn", "에러", "서버에 오류가 발생하였습니다.");
        },
        flow: "auth-code",
    });

    const validationHandler = (e) => {
        if (e.target.name === "email") {
            let regex = /^[A-Za-z0-9_.-]+@[A-Za-z0-9-]+\.[A-Za-z0-9-]+/;
            if (!regex.test(e.target.value)) {
                setError({ ...error, email: "이메일 형식이 올바르지 않습니다." });
            } else {
                setError({ ...error, email: undefined });
            }
        } else if (e.target.name === "password") {
            if (e.target.value.length < 6) {
                setError({ ...error, password: "비밀번호는 6자 이상이어야 합니다." });
            } else {
                setError({ ...error, password: undefined });
            }
        }
    };

    const isActiveLogin = () => {
        if (email && password) {
            let regex = /^[A-Za-z0-9_.-]+@[A-Za-z0-9-]+\.[A-Za-z0-9-]+/;
            if (!regex.test(email)) {
                return false;
            } else {
                if (password.length < 6) {
                    return false;
                }
            }
            return true;
        } else {
            return false;
        }
    };

    return (
        <GuestUserSingleCardLayout width={440}>
            <HtmlHead title={"로그인"} />
            <LogoColumn device={device}>
                <Image name={"loginLogo"} />
            </LogoColumn>
            <TitleText
                size={"large"}
                margin={"48px 0 4px 0"}
                fontWeight={"bold"}
                textAlign={device.isTabletOrPC ? "left" : "center"}
            >
                지금 시작해보세요
            </TitleText>
            <MessageWrap device={device}>
                <GeneralText size={"small"} margin={"0 8px 0 0"} color={"var(--color-Grey1)"}>
                    회원가입이 필요하신가요?
                </GeneralText>
                <Link to={"/signup"}>
                    <Button onlyText {...TextButtonStyle} buttonText={"회원가입"} />
                </Link>
            </MessageWrap>
            <InputWrap>
                <form>
                    {" "}
                    {/* 크롭 콘솔 에러 대응: input field 는 form 내부에 있어야 함 */}
                    <FormInput
                        margin="40px 0 32px 0"
                        width="100%"
                        label="이메일"
                        placeholder="이메일 주소를 입력해주세요"
                        type="email"
                        className="form-control"
                        name={"email"}
                        maxLength={30}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyDown={handleInputKeyDown}
                        errorMessage={error?.email}
                        onBlur={validationHandler}
                    />
                    <FormInput
                        margin="0 0 56px 0"
                        width="100%"
                        label="비밀번호"
                        placeholder="비밀번호를 입력해주세요"
                        type="password"
                        iconDp="flex"
                        className="form-control"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={handleInputKeyDown}
                        errorMessage={error?.password}
                        name={"password"}
                        onBlur={validationHandler}
                    />
                </form>
            </InputWrap>
            <SearchPwWrap>
                <Link to={"/reset-password"}>
                    <Button onlyText {...TextButtonStyle} buttonText={"비밀번호를 잊으셨나요?"} />
                </Link>
            </SearchPwWrap>
            <Button
                onlyText
                margin="4px 0 16px 0"
                bgColor="var(--color-Black)"
                fontColor="var(--color-White)"
                buttonText="로그인"
                className="btn btn-primary"
                type="submit"
                onClick={(e) => login()}
                disabled={!isActiveLogin()}
            />
            <DivideWrap>
                <DivideLine />
                <GeneralText size={"small"} margin={"0 8px "} color={"var(--color-Grey1)"}>
                    또는
                </GeneralText>
                <DivideLine />
            </DivideWrap>
            <Button
                margin="16px 0 0 0"
                bgColor={"var(--color-White)"}
                hoverBgColor={"var(--color-ButtonHover4)"}
                border="solid 1px var(--color-Grey3)"
                fontColor="var(--color-Black)"
                buttonText="구글 아이디로 계속하기"
                className="btn btn-primary"
                onClick={(e) => {
                    googleSocialLogin();
                }}
            >
                <ImgWrap src={GoogleIconImage} />
            </Button>

            <NaverLogin
                clientId={process.env.REACT_APP_SOCIAL_AUTH_NAVER_CLIENT_ID}
                callbackUrl={`${window.location.origin}/login`}
                render={(props) => (
                    <Button
                        margin="16px 0 0 0"
                        bgColor={"var(--color-White)"}
                        hoverBgColor={"var(--color-ButtonHover4)"}
                        border="solid 1px var(--color-Grey3)"
                        fontColor="var(--color-Black)"
                        buttonText="네이버 아이디로 계속하기"
                        className="btn btn-primary"
                        onClick={props.onClick}
                    >
                        <ImgWrap src={NaverIconImage} />
                    </Button>
                )}
                onSuccess={(naverUser) => {
                    // 사용자의 ID를 가져온다.
                    setLoading(true);

                    axios.post(apiUrl("naverLogin"), { user: naverUser }).then((data) => {
                        let email = data.data.user_email;
                        let encryptedEmail = encrypt(email);
                        localStorage.setItem("email", encryptedEmail);
                        localStorage.setItem("token", data.data.auth_token);
                        window.location = "/reference";
                        setLoading(false);
                    });
                }}
                onFailure={(result) => console.error(result)}
            />
        </GuestUserSingleCardLayout>
    );
}

export default LoginPage;
