import { apiUrl } from "utils/urls";

import axios from "axios";

axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    if (token) {
        config.headers["Authorization"] = `Token ${token}`;
    } else {
        delete config.headers["Authorization"];
    }
    return config;
});

export const loginApi = async (body) => {
    const { data } = await axios.post(apiUrl("login"), body);
    return data;
};

export const logout = async () => {
    const token = { auth_token: localStorage.getItem("token") };
    const { data } = await axios.post(apiUrl("logout"), token);
    return data;
};

export const signUpApi = async (body) => {
    const { data } = await axios.post(apiUrl("signup"), body);
    return data;
};

export const userCheck = async () => {
    const { data } = await axios.get(apiUrl("user") + "check");
    return data;
};

export const adminChangeUser = async (body) => {
    const { data } = await axios.post(apiUrl("adminChangeUser"), body);
    return data;
};
