import React, { useState } from "react";

import styled from "styled-components";

import Alert from "components/atoms/alert/Alert";
import useLoading from "components/atoms/loading/useLoading";

import FormInput from "components/molecules/FormInput";
import AbstractModalFooter from "components/page/modal/AbstractModalFooter";

const Container = styled.div`
    width: 100%;
`;

function TagEditModal(props) {
    const { item, mutation, exitModal } = props;
    const { setLoading } = useLoading();
    const [tagName, setTagName] = useState(item.name || "");

    const handleApply = () => {
        mutation.mutate(
            { name: tagName, ...(item.index && { id: item.index }) },
            {
                onSuccess: () => {
                    setLoading(false);
                    exitModal();
                },
                onError: (error, variables) => {
                    setLoading(false);
                    Alert("warn", "에러", error.response.data.name);
                },
            },
        );
    };

    return (
        <Container>
            <FormInput
                labelDp="none"
                placeholder="Tag Name"
                value={tagName}
                onChange={(e) => setTagName(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        handleApply();
                    }
                }}
            />
            <AbstractModalFooter
                leftBtnText={"취소"}
                rightBtnText={"적용"}
                leftOnClick={props.exitModal}
                rightOnClick={() => {
                    handleApply();
                }}
            />
        </Container>
    );
}

export default TagEditModal;
