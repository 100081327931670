const api_prefix = "/api/";
const ws_prefix = "/ws/";

const api_urls = {
    user: "user/",
    login: "user/login",
    logout: "user/logout",
    signup: "user/signup",
    unregister: "user/withdraw",
    googleLogin: "user/social/google/",
    naverLogin: "user/social/naver/",
    adminChangeUser: "user/admin/change/user",

    bibliography: "paper/reference",
    paperAddWithBibtex: "paper/reference/add-with-bibtex",
    review: "paper/review",
    research: "paper/research",
    researchOrder: "paper/research/order",
    referenceResearch: "paper/reference/research",
    referenceCitation: "paper/reference/research/citation",
    researchAnalysis: "paper/research/analysis",
    researchMedia: "paper/research/media",
    researchCreateWidthHtml: "paper/researches/convert-html",
    emailConfirm: "user/email/confirm",
    resetPassword: "user/reset/password",
    changePassword: "user/change/password",
    changeMarketing: "user/change/marketing",
    changeEnvSettings: "user/change/env-settings",
    selectBoxOptions: "common/select-box-options",
    tag: "paper/tag",

    // payment
    paymentBillingKey: "payment/billing/key",
    paymentBillingHistory: "payment/billing/history",
    paymentBillingReceipt: "payment/billing/receipt",
    paymentPlan: "payment/plan",
    paymentPlanSubscriptionHistory: "payment/plan/subscription/history",
    paymentCoupon: "payment/coupon",
    paymentCouponUse: "payment/coupon/use",
    paymentCouponRegister: "payment/coupon/register",
    paymentPlanGroup: "payment/group",
    paymentPlanGroupUser: "payment/group/user",

    // papers
    paperSearch: "paper/papers/search",
    paperRecommendation: "paper/papers/recommendation",
    paperDetail: "paper/papers/detail",
    paperRelatedPapers: "paper/papers/related-papers",
    paperMetadata: "paper/papers/metadata",
    paperStyle: "paper/papers/style",
    paperSearchHistory: "paper/search/history",
};

const sse_url = {
    ssePaperSummarize: "sse/paper/summarize",
};

const ws_urls = {
    review: "review",
    researchAnalysisConsumer: "research/analysis/consumer",
    referenceAnalysis: "reference/analysis",
    researchContentGenerator: "research/content/generate",
};

export function apiUrl(url) {
    return window.location.protocol + "//" + process.env.REACT_APP_API_BASE_URL + api_prefix + api_urls[url];
}

export function sseUrl(url) {
    return window.location.protocol + "//" + process.env.REACT_APP_API_BASE_URL + api_prefix + sse_url[url];
}

export function wsUrl(url) {
    const protocol = window.location.protocol === "https:" ? "wss://" : "ws://";

    return (
        protocol +
        process.env.REACT_APP_API_BASE_URL +
        ws_prefix +
        ws_urls[url] +
        `?token=${localStorage.getItem("token")}`
    );
}
