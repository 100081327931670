import React, { useEffect, useState } from "react";

import styled from "styled-components";

import GeneralText from "components/atoms/text/GeneralText";

import AbstractModalFooter from "components/page/modal/AbstractModalFooter";

import { useTagRecommendation, useTagReferenceCreate } from "hooks/queries/useBibliographies";

const Container = styled.div`
    width: 100%;
`;

const MessageLine = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0 0 0;
    width: 100%;
`;

const ContentsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px 0 40px 0;
    width: 100%;
    height: 320px;
`;

const NormalView = styled.div`
    display: block;
    padding: 8px;
    width: 100%;
    height: 320px;
    max-height: 320px;
    background-color: var(--color-Base1);
    border-radius: 4px;
    overflow: auto;
`;

const BlankView = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const TagListWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
`;

const TagItem = styled.div`
    margin: 0 4px 4px 0;
    padding: 6px;
    max-width: 240px;
    height: 28px;
    background-color: ${(props) => (props.selected ? "var(--color-TagActive)" : "var(--color-Tag)")};
    border: solid 1px transparent;
    border-radius: 2px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: 120ms ease-in;

    &:hover {
        border: solid 1px var(--color-Key);
    }
`;

function ReferenceTagRecommendModal(props) {
    const [recommendedTags, setRecommendedTags] = useState([]);

    const tagReferenceCreate = useTagReferenceCreate();
    const tagRecommendation = useTagRecommendation();

    useEffect(() => {
        if (props.referenceId) {
            tagRecommendation.mutate(
                { reference_id: props.referenceId },
                {
                    onSuccess: (data, variables, context) => {
                        setRecommendedTags(
                            data.tag_list.map((tag_name) => {
                                return {
                                    name: tag_name,
                                    selected: false,
                                };
                            }),
                        );
                    },
                    onError: (error, variables, context) => {
                        console.log("recommendation error");
                        console.log(error);
                    },
                },
            );
        }
    }, []);

    const handleApply = () => {
        tagReferenceCreate.mutate(
            {
                reference_ids: [props.referenceId],
                tag_ids: props.selectedTagIds,
                recommended_tags: recommendedTags.filter((tag) => tag.selected).map((tag) => tag.name),
                delete_old_tag: true,
                delete_old_reference: false,
            },
            {
                onSuccess: (data, variables, context) => {
                    props.exitModal();
                    props.refreshSelectedTags();
                },
                onError: (error, variables, context) => {
                    console.log("recommendation error");
                    console.log(error);
                },
            },
        );
    };

    return (
        <Container>
            <MessageLine>
                <GeneralText size={"regular"}>원하는 태그를 선택 후 적용 버튼을 누르세요.</GeneralText>
            </MessageLine>

            <ContentsWrapper>
                <NormalView>
                    {recommendedTags.length > 0 ? (
                        <TagListWrap>
                            {recommendedTags.map((tag, index) => (
                                <TagItem
                                    key={index}
                                    selected={tag.selected}
                                    onClick={(e) => {
                                        setRecommendedTags(
                                            recommendedTags.map((x) => {
                                                return {
                                                    name: x.name,
                                                    selected: x.name === tag.name ? !x.selected : x.selected,
                                                };
                                            }),
                                        );
                                    }}
                                >
                                    <GeneralText ellipsis size={"small"} color={"var(--color-Black)"}>
                                        {tag.name}
                                    </GeneralText>
                                </TagItem>
                            ))}
                        </TagListWrap>
                    ) : (
                        <BlankView>
                            <GeneralText size={"small"} textAlign={"center"} color={"var(--color-DisabledText)"}>
                                추천 태그가 없습니다.
                            </GeneralText>
                        </BlankView>
                    )}
                </NormalView>
            </ContentsWrapper>

            <AbstractModalFooter
                leftBtnText={"취소"}
                rightBtnText={"적용"}
                leftOnClick={props.exitModal}
                rightOnClick={handleApply}
            />
        </Container>
    );
}

export default ReferenceTagRecommendModal;
