class ConstantsManager {
    constructor() {
        this.PC_WIDTH = 1280;
        this.TABLET_WIDTH = 768;

        this.TABLET_OR_PC_HEADER_HEIGHT = 56;
        this.MOBILE_HEADER_HEIGHT = 48;
        this.BIG_HEIGHT_MQ = `@media (min-height: 1280px)`;

        this.BLUR_OPTION = {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            backdropFilter: "blur(8px)",
        };
        this.DIMED_OPTION = {
            backgroundColor: "rgba(0, 0, 0, 0.2)",
        };

        this.KB = 1024;
        this.MB = 1048576;
        this.GB = 1073741824;

        this.FREE_PLAN = 1;
        this.PAID_PLAN = 2;

        this.FILE_ANALYSIS_TODO = "TODO";
        this.FILE_ANALYSIS_DOING = "DOING";
        this.FILE_ANALYSIS_DONE = "DONE";
        this.FILE_ANALYSIS_UNSUPPORTED = "UNSUPPORTED";
        this.FILE_ANALYSIS_FAILED = "FAILED";

        this.REVIEW_MESSAGE_MEMO = "MEMO";
        this.REVIEW_MESSAGE_CHAT = "CHAT";
        this.REVIEW_CHAT_TRANSLATE = "TRANSLATE";
        this.REVIEW_CHAT_SUMMARIZE = "SUMMARIZE";
        this.REVIEW_CHAT_EXPLAIN = "EXPLAIN";
        this.REVIEW_CHAT_FREETALK = "FREETALK";

        this.SEARCH_FILTER_REGEX = /(is:|t:|r:)?("[^"]*"|\S*)(\s|\W|$)/g;

        this.TAG_TREE_ID_SIDEMENU = "tree-tag-side-menu";
        this.TAG_TREE_ID_EDIT_MODAL = "tree-tag-edit";

        this.BIBTEX_FIELD = {
            article: {
                citation_key: "required",
                author: "required",
                title: "required",
                journal: "required",
                year: "required",

                volume: "default",
                number: "default",
                pages: "default",

                month: "optional",
                note: "optional",
                doi: "optional",
                issn: "optional",
            },
            book: {
                citation_key: "required",
                author: "required",
                title: "required",
                publisher: "required",
                year: "required",

                editor: "default",
                address: "default",

                volume: "optional",
                number: "optional",
                series: "optional",
                edition: "optional",
                month: "optional",
                note: "optional",
            },
            booklet: {
                citation_key: "required",
                title: "required",

                author: "default",
                howpublished: "default",
                month: "default",
                year: "default",

                address: "optional",
                note: "optional",
            },
            conference: {
                citation_key: "required",
                author: "required",
                title: "required",
                booktitle: "required",
                year: "required",

                address: "default",
                publisher: "default",
                organization: "default",

                editor: "optional",
                volume: "optional",
                number: "optional",
                series: "optional",
                pages: "optional",
                month: "optional",
                note: "optional",
            },
            inbook: {
                citation_key: "required",
                author: "required",
                title: "required",
                chapter: "required",
                pages: "required",
                publisher: "required",
                year: "required",

                editor: "default",
                address: "default",
                edition: "default",
                month: "default",

                volume: "optional",
                number: "optional",
                series: "optional",
                type: "optional",
                note: "optional",
            },
            incollection: {
                citation_key: "required",
                author: "required",
                title: "required",
                booktitle: "required",
                publisher: "required",
                year: "required",

                editor: "default",
                pages: "default",
                address: "default",

                volume: "optional",
                number: "optional",
                series: "optional",
                type: "optional",
                chapter: "optional",
                edition: "optional",
                month: "optional",
                note: "optional",
            },
            inproceedings: {
                citation_key: "required",
                author: "required",
                title: "required",
                booktitle: "required",
                year: "required",

                series: "default",
                pages: "default",
                publisher: "default",
                address: "default",

                editor: "optional",
                volume: "optional",
                number: "optional",
                month: "optional",
                organization: "optional",
                note: "optional",
            },
            manual: {
                citation_key: "required",
                title: "required",

                author: "default",
                organization: "default",
                address: "default",
                year: "default",

                edition: "optional",
                month: "optional",
                note: "optional",
            },
            mastersthesis: {
                citation_key: "required",
                author: "required",
                title: "required",
                school: "required",
                year: "required",

                address: "default",
                month: "default",

                type: "optional",
                note: "optional",
            },
            misc: {
                citation_key: "required",

                author: "default",
                title: "default",
                howpublished: "default",

                year: "optional",
                month: "optional",
                note: "optional",
            },
            phdthesis: {
                citation_key: "required",
                author: "required",
                title: "required",
                school: "required",
                year: "required",

                address: "default",
                month: "default",

                type: "optional",
                note: "optional",
            },
            proceedings: {
                citation_key: "required",
                title: "required",
                year: "required",

                editor: "default",
                volume: "default",
                number: "default",
                series: "default",
                address: "default",
                publisher: "default",
                note: "default",

                organization: "optional",
                month: "optional",
            },
            techreport: {
                citation_key: "required",
                author: "required",
                title: "required",
                institution: "required",
                year: "required",

                number: "default",
                address: "default",
                month: "default",

                type: "optional",
                note: "optional",
            },
            unpublished: {
                citation_key: "required",
                author: "required",
                title: "required",
                note: "required",

                month: "default",
                year: "default",
            },
        };

        this.BIBTEX_FIELD_TYPE = {
            address: "string",
            abstract: "text",
            annote: "text",
            author: "multiple",
            booktitle: "string",
            chapter: "string",
            doi: "string",
            edition: "string",
            editor: "multiple",
            howpublished: "string",
            institution: "string",
            issn: "string",
            journal: "string",
            month: "number",
            note: "string",
            number: "string",
            organization: "string",
            pages: "string",
            publisher: "string",
            school: "string",
            series: "string",
            title: "string",
            type: "string",
            volume: "string",
            year: "number",
        };
    }
}

const Constants = new ConstantsManager();
export default Constants;
