import React, { useEffect, useRef, useState } from "react";
import { useIsFetching, useIsMutating } from "react-query";
import { useNavigate } from "react-router-dom";

import styled, { css } from "styled-components";

import Checkbox from "components/atoms/Checkbox";
import HtmlHead from "components/atoms/HtmlHead";
import Icon from "components/atoms/Icon";
import Tooltip from "components/atoms/Tooltip";
import Confirm from "components/atoms/alert/Confirm";
import Button from "components/atoms/button/Button";
import useLoading from "components/atoms/loading/useLoading";
import GeneralText from "components/atoms/text/GeneralText";
import TitleText from "components/atoms/text/TitleText";
import useToast from "components/atoms/toast/useToast";

import Table from "components/molecules/table/Table";
import {
    getColumnOrder,
    getColumnSize,
    getColumnVisibility,
    getDefaultConfig,
    updateColumnOrder,
} from "components/molecules/table/TableManager";
import AbstractModal from "components/page/modal/AbstractModal";
import ResearchAddModal from "components/page/modal/ResearchAddModal";
import LoginUserLayout from "components/templates/LoginUserLayout";

import { useResearchBulkDelete, useResearchUpdate, useResearches } from "hooks/queries/useResearch";
import { useSessionPageHistory } from "hooks/useSessionPageHistory";

import { useDevice } from "utils/device";

const Header = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 48px;
    height: 48px;
    max-height: 48px;
    background-color: var(--color-White);
`;

const HeaderLeftWrap = styled.div`
    display: flex;
    align-items: center;
`;

const HeaderRightWrap = styled.div`
    position: relative;
    margin: 0 0 0 40px;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 48px 0 0 0;
    padding: 0 48px;
    width: 100%;
    height: calc(100vh - 104px);
`;

const BodyHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
`;

const BodyHeaderLeftSection = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    width: 171px;
    margin-left: 10px;
`;

const BodyHeaderRightSection = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    margin: 0 0 0 24px;
`;

const SearchWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 12px 0 0;
    width: 100%;
    min-width: 200px;
    max-width: 320px;
`;

const SearchInputBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1px 4px;
    width: 100%;
    height: 28px;
    background-color: var(--color-White);
    border: solid 1px var(--color-Line);
    border-radius: 4px;

    &:focus-within {
        border: solid 1px var(--color-Key);
    }
`;

const SearchInput = styled.input`
    padding: 0 8px;
    width: calc(100% - 24px);
    height: 24px;
    font-size: 13px;
    font-weight: 400;
    color: var(--color-Black);
    background-color: transparent;
    border: transparent;
    outline: var(--color-Key);
`;

const SearchButtonWrap = styled.div`
    display: flex;
    align-items: center;
    height: 24px;
`;

const BookmarkWrap = styled.div`
    display: flex;
    align-items: center;
`;

const BlankView = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const AddInfoSection = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: auto;
    margin-bottom: 24px;
`;

const AddInfoBox = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    padding: 16px;
    width: 100%;
    border-radius: 8px;
    background-color: var(--color-InfoBox);

    ${(props) =>
        props.device.isPC &&
        css`
            padding: 24px;
        `};
`;

const AddInfoItem = styled.div`
    width: 50%;
    max-width: calc(50% - 8px);
`;

const AddInfoItemRow = styled.div`
    display: flex;
    align-items: center;
    margin: 0 0 8px 6px;
    width: 100%;

    &:last-child {
        margin: 0 0 0 6px;
    }
`;

const AddInfoSubItemWrap = styled.div`
    margin: 0 0 8px 32px;
`;

const AddInfoSubItem = styled.div`
    display: flex;
    align-items: center;
`;

const DivideLine = styled.span`
    display: block;
    margin: 0 12px;
    width: 1px;
    height: 16px;
    background-color: var(--color-Outline);
`;

const CheckIconStyle = {
    name: "check",
    size: "14",
    color: "var(--color-DarkKey)",
};

const ItemTextStyle = {
    margin: "0 0 0 8px",
    size: "large",
    color: "var(--color-SubBlack)",
};

const SubItemTextStyle = {
    margin: "0 0 4px 4px",
    size: "small",
    color: "var(--color-SubBlack)",
};

const defaultSearchParams = {
    page_size: 10,
    page: 1,
    is_bookmarked: false,
    search: "",
    ordering: "",
};

function ResearchListPage(props) {
    const navigate = useNavigate();
    const checkedBibsRef = useRef([]);

    const device = useDevice();
    const { setToast } = useToast();
    const { setLoading } = useLoading();

    const updateResearchMutation = useResearchUpdate();
    const deleteBulkResearchMutation = useResearchBulkDelete();

    const [searchFilter, setSearchFilter] = useState("");
    const [searchParams, setSearchParams] = useState({ ...defaultSearchParams });
    const researchQuery = useResearches(searchParams);
    const [researches, setResearches] = useState({
        count: 0,
        results: [],
    });

    const [addResearchModal, setAddResearchModal] = useState(false);

    const rerenderTable = React.useReducer(() => ({}), {})[1];

    const isFetching = useIsFetching();
    const isMutating = useIsMutating();

    useEffect(() => {
        return () => {
            researchQuery.remove();
        };
    }, []);

    useEffect(() => {
        if (searchParams) {
            researchQuery.refetch();
        }
    }, [searchParams]);

    useEffect(() => {
        if (isFetching > 0 || isMutating > 0) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [isFetching, isMutating]);

    const handleCheckboxAllChange = (e) => {
        // 전체 선택이면 모든 id를, 그렇지 않으면 빈 배열을 반환
        checkedBibsRef.current = e.target.checked ? researches?.results?.map((research) => research.id) : [];
        rerenderTable();
    };

    const handleCheckboxChange = (event) => {
        let { id } = event.target;
        id = Number(id);

        const index = checkedBibsRef.current.indexOf(id);
        if (index > -1) {
            checkedBibsRef.current = checkedBibsRef.current.filter((item) => item !== id);
        } else {
            checkedBibsRef.current = [...checkedBibsRef.current, id];
        }
        rerenderTable();
    };

    const columns = React.useMemo(
        () => [
            {
                id: "id",
                accessorKey: "id",
                header: null,
                name: "선택",
                size: 36,
                minSize: 36,
                maxSize: 36,
                enableResizing: false,
                content: (info) => {
                    const value = info.getValue();
                    return (
                        <Checkbox
                            id={value}
                            checked={checkedBibsRef.current.includes(value)}
                            onChange={handleCheckboxChange}
                        />
                    );
                },
            },
            {
                id: "is_bookmarked",
                accessorKey: "is_bookmarked",
                header: null,
                name: "즐겨찾기",
                size: 36,
                minSize: 36,
                maxSize: 36,
                enableResizing: false,
                content: (info) => {
                    return (
                        <Button
                            onlyIcon
                            onClick={(e) => {
                                e.stopPropagation();
                                updateResearchMutation.mutate(
                                    {
                                        id: info.row.original.id,
                                        is_bookmarked: !info.getValue(),
                                    },
                                    {
                                        onSuccess: (data) => {
                                            rerenderTable();
                                        },
                                    },
                                );
                            }}
                            bgColor={"transparent"}
                            hoverBgColor={"transparent"}
                        >
                            {info.row.original.is_bookmarked ? (
                                <Icon name={"bookmark"} color={"var(--color-Key)"} size={"16px"} />
                            ) : (
                                <Icon name={"bookmarkDisactive"} size={"16px"} />
                            )}
                        </Button>
                    );
                },
            },
            {
                id: "display_name",
                accessorKey: "display_name",
                name: "리서치 이름",
                size: 200,
                text: (info) => {
                    return info.getValue();
                },
            },
            {
                id: "plain_text_content",
                accessorKey: "plain_text_content",
                name: "내용",
                style: { justifyContent: "left" },
                text: (info) => {
                    return info.getValue();
                },
            },
            {
                id: "created_at",
                accessorKey: "created_at",
                name: "생성일시",
                size: 130,
                text: (info) => {
                    return info.getValue();
                },
            },
            {
                id: "updated_at",
                accessorKey: "updated_at",
                name: "수정일시",
                size: 130,
                text: (info) => {
                    return info.getValue();
                },
            },
        ],
        [],
    );

    useEffect(() => {
        if (researchQuery.data) {
            setResearches(researchQuery.data);
        }
    }, [researchQuery.data]);

    const handleToggleBookmark = () => {
        setSearchParams({ ...searchParams, is_bookmarked: !searchParams.is_bookmarked, page: 1 });
    };

    const handlePageChange = (newPage) => {
        setSearchParams({ ...searchParams, page: newPage });
    };

    const handlePageSizeChange = (newSize) => {
        setSearchParams({ ...searchParams, page_size: newSize, page: 1 });
    };

    const handleSortChange = (sort) => {
        const sortValue = sort
            .map((sortItem) => {
                return sortItem.desc ? `-${sortItem.id}` : sortItem.id;
            })
            .join(",");
        setSearchParams({ ...searchParams, ordering: sortValue, page: 1 });
    };
    const PAGE_KEY = "research-list";

    const [tableConfig, setTableConfig] = useState(
        localStorage.getItem(PAGE_KEY)
            ? JSON.parse(localStorage.getItem(PAGE_KEY)).research
            : getDefaultConfig(columns),
    );

    useEffect(() => {
        const initialConfig = getColumnSize(columns, tableConfig);
        // columns 업데이트
        columns.forEach((column) => {
            const config = initialConfig.find((item) => item.id === column.id);
            if (config) {
                column.size = config.size;
            }
        });
    }, []);

    useEffect(() => {
        if (tableConfig.length !== 0) {
            if (tableConfig.length !== columns.length) {
                columns.forEach((column) => {
                    const config = tableConfig.find((item) => item.id === column.id);
                    if (!config) {
                        tableConfig.push({
                            id: column.id,
                            size: column.size,
                            visibility: column.defaultVisibility === undefined ? false : column.defaultVisibility,
                            order: tableConfig.length,
                        });
                    }
                });
                setTableConfig(tableConfig);
                tableConfig.forEach((config) => {
                    const column = columns.find((item) => item.id === config.id);
                    if (!column) {
                        setTableConfig((prev) => prev.filter((item) => item.id !== config.id));
                    }
                });
            }
        }

        localStorage.setItem(
            PAGE_KEY,
            JSON.stringify({
                research: tableConfig,
            }),
        );
    }, [tableConfig]);

    const { prevPage } = useSessionPageHistory();

    const saveSessionCache = () => {
        sessionStorage.setItem(
            PAGE_KEY,
            JSON.stringify({
                searchParams: searchParams,
            }),
        );
    };

    const loadSessionCache = () => {
        const sessionCacheJson = sessionStorage.getItem(PAGE_KEY);
        if (sessionCacheJson) {
            const sessionCacheObj = JSON.parse(sessionCacheJson);
            setSearchParams(sessionCacheObj.searchParams);
        }
    };

    const clearSessionCache = () => {
        sessionStorage.removeItem(PAGE_KEY);
    };

    useEffect(() => {
        // 상세 페이지에서 이동해왔을 때 페이지 복원
        if (prevPage && prevPage.startsWith("/research/")) {
            loadSessionCache();
            clearSessionCache(); // 새로고침 했을 때 기본값으로 처리되도록 삭제
            researchQuery.remove();
        }
    }, [prevPage]);

    useEffect(() => {
        // 상세 페이지에서 돌아왔을 때 검색창에 텍스트 자동 입력 처리
        setSearchFilter(searchParams.search);
    }, [searchParams.search]);

    const tableRef = useRef();

    useEffect(() => {
        checkedBibsRef.current = [];
        rerenderTable();
    }, [researches?.results]);

    return (
        <LoginUserLayout>
            <HtmlHead title={"Research"} />
            <Header>
                <HeaderLeftWrap>
                    <TitleText size={"small"} margin={"0 16px 0 0"}>
                        Research
                    </TitleText>
                    <GeneralText size={"small"} margin={"0 2px 0 0"}>
                        전체
                    </GeneralText>
                    <GeneralText size={"small"}>{researches.count}건</GeneralText>
                </HeaderLeftWrap>
                <HeaderRightWrap>
                    <Button
                        onClick={() => setAddResearchModal(!addResearchModal)}
                        width={"132px"}
                        height={"32px"}
                        bgColor={"var(--color-Button2)"}
                        hoverBgColor={"var(--color-ButtonHover2)"}
                        border={"solid 2px transparent"}
                        fontColor={"var(--color-White)"}
                        buttonText={"리서치 추가"}
                    >
                        <Icon name={"plus"} size={"12"} color={"var(--color-White)"} />
                    </Button>
                </HeaderRightWrap>
            </Header>
            <Body>
                {researches?.count !== 0 ? (
                    <>
                        <BodyHeader>
                            <BodyHeaderLeftSection>
                                <Checkbox
                                    margin={"0 20px 0 0"}
                                    indeterminate={
                                        checkedBibsRef.current.length > 0 &&
                                        checkedBibsRef.current.length < researches?.results?.length
                                    }
                                    checked={checkedBibsRef.current.length === researches?.results?.length}
                                    onChange={handleCheckboxAllChange}
                                />
                                <Tooltip message={"삭제"}>
                                    <Button
                                        onlyIcon
                                        margin={"0 4px 0 0"}
                                        bgColor={"var(--color-Button2)"}
                                        hoverBgColor={"var(--color-ButtonHover2)"}
                                        onClick={() => {
                                            Confirm(
                                                "warn",
                                                "리서치 삭제",
                                                "선택한 리서치를 삭제하시겠습니까?",
                                                "삭제",
                                                (e) => {
                                                    deleteBulkResearchMutation.mutate(
                                                        { ids: checkedBibsRef.current },
                                                        {
                                                            onSuccess: (data, variables, context) => {
                                                                setToast("성공적으로 삭제되었습니다.", "info");
                                                                checkedBibsRef.current = [];
                                                            },
                                                        },
                                                    );
                                                },
                                            );
                                        }}
                                        disabled={checkedBibsRef.current.length === 0}
                                    >
                                        <Icon name={"delete"} size={"12"} color={"var(--color-White)"} />
                                    </Button>
                                </Tooltip>
                            </BodyHeaderLeftSection>
                            <BodyHeaderRightSection>
                                <SearchWrap>
                                    <SearchInputBox>
                                        <SearchInput
                                            value={searchFilter}
                                            onChange={(e) => setSearchFilter(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    setSearchParams({ ...searchParams, search: searchFilter, page: 1 });
                                                }
                                            }}
                                        />
                                        <SearchButtonWrap>
                                            {searchFilter && (
                                                <Tooltip message={"초기화"}>
                                                    <Button
                                                        onlyIcon
                                                        bgColor={"var(--color-White)"}
                                                        hoverBgColor={"var(--color-ButtonHover4)"}
                                                        onClick={(e) => {
                                                            setSearchFilter("");
                                                            setSearchParams({ ...searchParams, search: "", page: 1 });
                                                        }}
                                                    >
                                                        <Icon name={"x"} color={"var(--color-SubBlack)"} />
                                                    </Button>
                                                </Tooltip>
                                            )}
                                            <Tooltip message={"검색"}>
                                                <Button
                                                    onlyIcon
                                                    bgColor={"var(--color-White)"}
                                                    hoverBgColor={"var(--color-ButtonHover4)"}
                                                    onClick={(e) => {
                                                        setSearchParams({
                                                            ...searchParams,
                                                            search: searchFilter,
                                                            page: 1,
                                                        });
                                                    }}
                                                >
                                                    <Icon name={"search"} size={"12"} color={"var(--color-SubBlack)"} />
                                                </Button>
                                            </Tooltip>
                                        </SearchButtonWrap>
                                    </SearchInputBox>
                                </SearchWrap>
                                <BookmarkWrap>
                                    <Tooltip message={"즐겨찾기만 보기"}>
                                        <Button
                                            onlyIcon
                                            buttonSize={"24px"}
                                            bgColor={"var(--color-White)"}
                                            hoverBgColor={"none"}
                                            border={
                                                searchParams.is_bookmarked
                                                    ? "solid 2px var(--color-Key)"
                                                    : "solid 2px var(--color-DisabledButton)"
                                            }
                                            onClick={() => handleToggleBookmark()}
                                        >
                                            <Icon
                                                name={"bookmark"}
                                                size={"12px"}
                                                color={
                                                    searchParams.is_bookmarked
                                                        ? "var(--color-Key)"
                                                        : "var(--color-DisabledButton)"
                                                }
                                            />
                                        </Button>
                                    </Tooltip>
                                </BookmarkWrap>
                                <DivideLine />
                                <Tooltip message={"테이블 너비를 현재 화면 크기에 맞게 조정합니다"}>
                                    <Button
                                        onlyIcon
                                        transparentDisabled
                                        bgColor={"var(--color-Button4)"}
                                        hoverBgColor={"var(--color-ButtonHover4)"}
                                        onClick={() => {
                                            tableRef.current.expandColumn();
                                        }}
                                    >
                                        <Icon name={"expand"} size={"14"} color={"var(--color-Black)"} />
                                    </Button>
                                </Tooltip>
                            </BodyHeaderRightSection>
                        </BodyHeader>
                        <Table
                            ref={tableRef}
                            data={researches?.results || []}
                            columns={columns}
                            useSizingColumns={true}
                            useSortingColumns={true}
                            useOrderColumns={true}
                            pagination={{
                                pageIndex: searchParams.page,
                                pageSize: searchParams.page_size,
                                count: researches?.count,
                            }}
                            setPagination={(page) => {
                                if (Number(searchParams.page_size) !== Number(page.pageSize)) {
                                    handlePageSizeChange(page.pageSize);
                                } else if (searchParams.page !== Number(page.pageIndex)) {
                                    handlePageChange(page.pageIndex);
                                } else {
                                    return;
                                }
                            }}
                            sorting={
                                searchParams?.ordering
                                    ? [
                                          {
                                              id: searchParams.ordering.replace("-", ""),
                                              desc: searchParams.ordering.startsWith("-"),
                                          },
                                      ]
                                    : []
                            }
                            setSorting={handleSortChange}
                            onRowClick={(row) => {
                                saveSessionCache();
                                navigate(`/research/${row.original.uuid}`);
                            }}
                            onSizeChange={(changedColumn) => {
                                Object.keys(changedColumn).forEach((key) => {
                                    setTableConfig((prev) =>
                                        prev.map((item) =>
                                            item.id === key ? { ...item, size: changedColumn[key] } : item,
                                        ),
                                    );
                                });
                            }}
                            columnVisibility={getColumnVisibility(tableConfig)}
                            columnOrder={getColumnOrder(tableConfig)}
                            setColumnOrder={(oldIndex, newIndex) =>
                                setTableConfig(updateColumnOrder(tableConfig, oldIndex, newIndex))
                            }
                        />
                    </>
                ) : searchParams.search ? (
                    <>
                        <BodyHeader>
                            <BodyHeaderLeftSection>
                                <Checkbox
                                    margin={"0 8px 0 0"}
                                    indeterminate={
                                        checkedBibsRef.current.length > 0 &&
                                        checkedBibsRef.current.length < researches?.results?.length
                                    }
                                    checked={checkedBibsRef.current.length === researches?.results?.length}
                                    onChange={handleCheckboxAllChange}
                                />
                                <Tooltip message={"삭제"}>
                                    <Button
                                        onlyIcon
                                        margin={"0 4px 0 0"}
                                        bgColor={"var(--color-Button2)"}
                                        hoverBgColor={"var(--color-ButtonHover2)"}
                                        onClick={() => {
                                            Confirm(
                                                "warn",
                                                "리서치 삭제",
                                                "선택한 리서치를 삭제하시겠습니까?",
                                                "삭제",
                                                (e) => {
                                                    deleteBulkResearchMutation.mutate(
                                                        { ids: checkedBibsRef.current },
                                                        {
                                                            onSuccess: (data, variables, context) => {
                                                                setToast("성공적으로 삭제되었습니다.", "info");
                                                                checkedBibsRef.current = [];
                                                            },
                                                        },
                                                    );
                                                },
                                            );
                                        }}
                                        disabled={checkedBibsRef.current.length === 0}
                                    >
                                        <Icon name={"delete"} size={"12"} color={"var(--color-White)"} />
                                    </Button>
                                </Tooltip>
                            </BodyHeaderLeftSection>
                            <BodyHeaderRightSection>
                                <SearchWrap>
                                    <SearchInputBox>
                                        <SearchInput
                                            value={searchFilter}
                                            onChange={(e) => setSearchFilter(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    setSearchParams({
                                                        ...searchParams,
                                                        search: searchFilter,
                                                        page: 1,
                                                    });
                                                }
                                            }}
                                        />
                                        <SearchButtonWrap>
                                            {searchFilter && (
                                                <Tooltip message={"초기화"}>
                                                    <Button
                                                        onlyIcon
                                                        bgColor={"var(--color-White)"}
                                                        hoverBgColor={"var(--color-ButtonHover4)"}
                                                        onClick={() => {
                                                            setSearchFilter("");
                                                            setSearchParams({ ...searchParams, search: "", page: 1 });
                                                        }}
                                                    >
                                                        <Icon name={"x"} color={"var(--color-SubBlack)"} />
                                                    </Button>
                                                </Tooltip>
                                            )}
                                            <Tooltip message={"검색"}>
                                                <Button
                                                    onlyIcon
                                                    bgColor={"var(--color-White)"}
                                                    hoverBgColor={"var(--color-ButtonHover4)"}
                                                    onClick={(e) => {
                                                        setSearchParams({
                                                            ...searchParams,
                                                            search: searchFilter,
                                                            page: 1,
                                                        });
                                                    }}
                                                >
                                                    <Icon name={"search"} size={"12"} color={"var(--color-SubBlack)"} />
                                                </Button>
                                            </Tooltip>
                                        </SearchButtonWrap>
                                    </SearchInputBox>
                                </SearchWrap>
                                <BookmarkWrap>
                                    <Button
                                        onlyIcon
                                        margin={"0 8px 0 0"}
                                        buttonSize={"24px"}
                                        bgColor={"var(--color-White)"}
                                        hoverBgColor={"none"}
                                        border={
                                            searchParams.is_bookmarked
                                                ? "solid 2px var(--color-Key)"
                                                : "solid 2px var(--color-DisabledButton)"
                                        }
                                        onClick={() => handleToggleBookmark()}
                                    >
                                        <Icon
                                            name={"bookmark"}
                                            size={"12px"}
                                            color={
                                                searchParams.is_bookmarked
                                                    ? "var(--color-Key)"
                                                    : "var(--color-DisabledButton)"
                                            }
                                        />
                                    </Button>
                                    <GeneralText size={"small"}>즐겨찾기만 보기</GeneralText>
                                </BookmarkWrap>
                            </BodyHeaderRightSection>
                        </BodyHeader>
                        <BlankView>
                            <GeneralText size={"large"} margin={"0 0 16px 0"}>
                                일치하는 리서치가 없습니다.
                            </GeneralText>
                            <GeneralText size={"small"} textAlign={"center"} color={"var(--color-Grey2)"}>
                                다른 검색어로 찾아보세요.
                            </GeneralText>
                        </BlankView>
                    </>
                ) : (
                    <>
                        <BlankView>
                            <GeneralText size={"large"} margin={"0 0 24px 0"}>
                                아직 리서치가 없습니다.
                            </GeneralText>
                            <GeneralText margin={"0 0 4px 0"} size={"large"} textAlign={"center"}>
                                <strong>[리서치 추가]</strong> 버튼을 선택하여 참고문헌을 추가하세요.
                            </GeneralText>
                        </BlankView>
                        <AddInfoSection>
                            <AddInfoBox device={device}>
                                <AddInfoItem>
                                    <GeneralText margin={"0 0 16px 0"} size={"large"} fontWeight={"600"}>
                                        리서치를 추가하면 다음 기능들을 이용할 수 있습니다.
                                    </GeneralText>
                                    <AddInfoItemRow>
                                        <Icon {...CheckIconStyle} />
                                        <GeneralText {...ItemTextStyle}>리서치에 참고문헌을 연결하여 관리</GeneralText>
                                    </AddInfoItemRow>
                                    <AddInfoItemRow>
                                        <Icon {...CheckIconStyle} />
                                        <GeneralText {...ItemTextStyle}>리서치별 참고문헌 인용 관리</GeneralText>
                                    </AddInfoItemRow>
                                    <AddInfoItemRow>
                                        <Icon {...CheckIconStyle} />
                                        <GeneralText {...ItemTextStyle}>리서치 노트 작성</GeneralText>
                                    </AddInfoItemRow>
                                </AddInfoItem>
                                <AddInfoItem>
                                    <GeneralText margin={"0 0 16px 0"} size={"large"} fontWeight={"600"}>
                                        리서치 노트에서 다음의 기능들을 이용할 수 있습니다.
                                    </GeneralText>
                                    <AddInfoItemRow>
                                        <Icon {...CheckIconStyle} />
                                        <GeneralText {...ItemTextStyle}>AI 번역, 교정</GeneralText>
                                    </AddInfoItemRow>
                                    <AddInfoItemRow>
                                        <Icon {...CheckIconStyle} />
                                        <GeneralText {...ItemTextStyle}>AI 글쓰기 보조 기능</GeneralText>
                                    </AddInfoItemRow>
                                    <AddInfoSubItemWrap>
                                        <AddInfoSubItem>
                                            <GeneralText size={"large"} color={"var(--color-SubBlack)"}>
                                                &#183;
                                            </GeneralText>
                                            <GeneralText {...SubItemTextStyle}>
                                                노트 내용 늘이기, 줄이기, 이어쓰기
                                            </GeneralText>
                                        </AddInfoSubItem>
                                    </AddInfoSubItemWrap>
                                    <AddInfoItemRow>
                                        <Icon {...CheckIconStyle} />
                                        <GeneralText {...ItemTextStyle}>
                                            노트에 인용문 추가 및 인용스타일 관리
                                        </GeneralText>
                                    </AddInfoItemRow>
                                </AddInfoItem>
                            </AddInfoBox>
                        </AddInfoSection>
                    </>
                )}
            </Body>
            {addResearchModal && (
                <AbstractModal
                    modalTitle="새 리서치 만들기"
                    width={480}
                    exitModal={(e) => setAddResearchModal(!addResearchModal)}
                >
                    <ResearchAddModal
                        exitModal={(e) => setAddResearchModal(!addResearchModal)}
                        lastOrder={researches?.count?.length > 0 ? researches?.count + 1 : 1}
                        onSuccess={(data) => {
                            navigate(`/research/${data.uuid}`, { state: { type: "create" } });
                        }}
                    />
                </AbstractModal>
            )}
        </LoginUserLayout>
    );
}

export default ResearchListPage;
