import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import styled from "styled-components";

import Icon from "components/atoms/Icon";
import SelectBox from "components/atoms/SelectBox";
import Alert from "components/atoms/alert/Alert";
import Button from "components/atoms/button/Button";
import useLoading from "components/atoms/loading/useLoading";
import TitleText from "components/atoms/text/TitleText";

import AbstractModal from "components/page/modal/AbstractModal";
import ReferenceBibInfoViewModal from "components/page/modal/ReferenceBibInfoViewModal";

import { useBibJsonUpdate } from "hooks/queries/useBibliographies";

import constants from "utils/constants";

import { v4 as uuidv4 } from "uuid";

const Container = styled.div`
    width: 100%;
`;

const TitleLineWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 16px 0;
`;

const TabviewWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const FormContainer = styled.form`
    position: relative;
    width: 100%;
`;

const FormInner = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
`;

const FormRow = styled.div`
    display: ${(props) => (props.field === "abstract" ? "none" : "flex")};
    justify-content: initial;
    align-items: flex-start;
    margin: 0 0 8px 0;
    width: 100%;
`;

const ItemLeft = styled.div`
    display: flex;
    align-items: center;
    margin: 0 8px 0 0;
    width: 140px;
    height: 24px;
`;

const ItemRight = styled.div`
    display: flex;
    align-items: flex-start;
    width: calc(100% - 148px);
`;

const LabelSign = styled.div`
    min-width: 2px;
    height: 12px;
    background-color: var(--color-Key);
`;

const FormLabelWrap = styled.div`
    position: relative;
    top: 4px;
    display: flex;
    align-items: flex-start;
    margin: 0 0 8px 0;
    padding: 0 0 0 8px;
    overflow-wrap: anywhere;
`;

const FormLabel = styled.label`
    margin: 0;
    font-size: 13px;
    font-weight: 400;
    color: var(--color-SubBlack);
    cursor: text;
`;

const FormContent = styled.div`
    display: flex;
    width: 100%;
`;

const FormInput = styled.input`
    flex: 1;
    margin: 0;
    padding: 0 12px;
    height: 24px;
    font-size: 12px;
    font-weight: 400;
    color: var(--color-SubBlack);
    background-color: var(--color-White);
    border: solid 1px ${(props) => (props.hasError ? "var(--color-Warn)" : "var(--color-Outline)")};
    border-radius: 4px;
    box-sizing: border-box;

    &:disabled {
        background-color: var(--color-DisabledInput);
    }
`;

const WithDelBtn = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const FieldAddArea = styled.div`
    // 버튼들이 안에 들어감 좌측정렬되어야 함
    margin: 16px 0 0 0;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    row-gap: 8px;
`;

const ErrorMessage = styled.div`
    position: relative;
    width: calc(100% - 148px);
    left: 148px;
    color: var(--color-Warn);
    height: 18px;
    font-size: 10px;
    font-weight: 400;
    margin-bottom: 8px;
`;

function TabReferenceBibInfo(props) {
    const { setLoading } = useLoading();

    const [bibJson, setBibJson] = useState({});
    const [deletedField, setDeletedField] = useState([]);
    const bibJsonMutation = useBibJsonUpdate();
    const [error, setError] = useState({});
    const {
        register,
        setValue,
        unregister,
        formState: { errors },
    } = useForm();

    const [showReferenceBibInfoViewModal, setShowReferenceBibInfoViewModal] = useState(false);

    const sortBibJson = (bibJson) => {
        // 1. entry_type 2. citation_key, 3. constants, 4. etc 순으로 정렬한다.
        const sortedBibJson = {};
        const copiedBibJson = JSON.parse(JSON.stringify(bibJson));
        const entryType = copiedBibJson.entry_type;
        const citationKey = copiedBibJson.citation_key;

        delete copiedBibJson.entry_type;
        delete copiedBibJson.citation_key;

        sortedBibJson.entry_type = entryType;
        sortedBibJson.citation_key = citationKey;
        if (copiedBibJson.abstract) {
            setValue("abstract", copiedBibJson.abstract);
        }
        // required field가 다음에 쌓이게 변경
        const fieldList = constants.BIBTEX_FIELD[entryType];
        Object.keys(fieldList).map((field) => {
            if (sortedBibJson[field] === undefined && copiedBibJson[field] !== undefined) {
                sortedBibJson[field] = copiedBibJson[field];
            }
        });

        Object.keys(copiedBibJson)
            .sort()
            .forEach((key) => {
                sortedBibJson[key] = copiedBibJson[key];
            });
        return sortedBibJson;
    };

    useEffect(() => {
        if (props.bibliography.bib_json) {
            if (bibJson !== props.bibliography.bib_json) setBibJson(sortBibJson(props.bibliography.bib_json));
        }
    }, [props.bibliography.bib_json]);

    useEffect(() => {
        if (Object.keys(bibJson).length !== 0) {
            setDeletedField(
                Object.keys(constants.BIBTEX_FIELD[bibJson.entry_type]).filter((field) => bibJson[field] === undefined),
            );
        }
    }, [bibJson]);

    const fieldDelete = (fieldName) => {
        const newBibJson = { ...bibJson };
        delete newBibJson[fieldName];
        handleUpdate(newBibJson);
    };

    const handleOnKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.target.blur();
        }
    };

    const entryTypeChange = (value) => {
        const newBibJson = { ...bibJson };
        newBibJson.entry_type = value;
        const fieldList = constants.BIBTEX_FIELD[value];
        Object.keys(fieldList).map((field) => {
            if (fieldList[field] !== "optional" && !newBibJson[field]) {
                newBibJson[field] = "";
            }
        });
        Object.keys(newBibJson).map((field) => {
            if (!fieldList[field] && field !== "entry_type" && newBibJson[field] === "") {
                delete newBibJson[field];
            }
        });
        handleUpdate(newBibJson);
    };

    const handleUpdate = (changedBibJson) => {
        const sortedBibJson = sortBibJson(changedBibJson);
        setBibJson(sortedBibJson);
        bibJsonMutation.mutate(
            {
                id: props.bibliography.id,
                bib_json: sortedBibJson,
            },
            {
                onMutate: (variables) => {
                    setLoading(true);
                },
                onSettled: (data, variables, context) => {
                    setLoading(false);
                },
                onSuccess: (data, variables, context) => {
                    setError({});
                },
                onError: (error, variables, context) => {
                    console.log(error.response.data);
                    setError(error.response.data);
                    if (error.response.data.bib_json) {
                        Alert("warn", "에러", error.response.data.bib_json);
                    }
                },
            },
        );
    };

    return (
        <Container>
            <TitleLineWrap>
                <TitleText size={"small"} margin={"0 8px 0 0"}>
                    서지정보
                </TitleText>
                <Button
                    onlyText
                    onClick={(e) => setShowReferenceBibInfoViewModal(true)}
                    margin={"0 32px 0 0"}
                    width={"52px"}
                    height={"24px"}
                    bgColor={"var(--color-Button2)"}
                    hoverBgColor={"var(--color-ButtonHover2)"}
                    fontColor={"var(--color-White)"}
                    buttonText={"BibTeX"}
                    fontSize={"small"}
                />
            </TitleLineWrap>
            <TabviewWrap
                onSubmit={() => {
                    return;
                }}
            >
                <FormContainer key={uuidv4()}>
                    <FormInner>
                        {Object.entries(bibJson).map(([field, value]) => {
                            if (field === "entry_type") {
                                return (
                                    <FormRow key={uuidv4()}>
                                        <ItemLeft>
                                            <LabelSign />
                                            <FormLabelWrap>
                                                <FormLabel>
                                                    {field} {" *"}
                                                </FormLabel>
                                            </FormLabelWrap>
                                        </ItemLeft>
                                        <ItemRight>
                                            <FormContent>
                                                <WithDelBtn>
                                                    <SelectBox
                                                        height={"24px"}
                                                        width={"calc(100% - 32px)"}
                                                        optionWidth="100%"
                                                        value={value}
                                                        topBottom={"top"}
                                                        top={"24px"}
                                                        textAlign={"left"}
                                                        items={Object.keys(constants.BIBTEX_FIELD).map((key) => {
                                                            return {
                                                                id: key,
                                                                name: key,
                                                            };
                                                        })}
                                                        onChange={(value) => entryTypeChange(value)}
                                                    />
                                                </WithDelBtn>
                                            </FormContent>
                                        </ItemRight>
                                    </FormRow>
                                );
                            }

                            if (field === "abstract") return;

                            const fieldList = constants.BIBTEX_FIELD[bibJson.entry_type];
                            const isDeletedList = [];
                            Object.keys(fieldList).map((key) => {
                                if (!bibJson[key]) {
                                    isDeletedList.push(key);
                                }
                            });

                            const options = {
                                value: value,
                                shouldUnregister: fieldList[field] !== "required",
                            };
                            if (constants.BIBTEX_FIELD_TYPE[field] === "multiple") {
                                const fieldValues = value?.split(" and ") || [];

                                return (
                                    <div key={uuidv4()}>
                                        {fieldValues.map((fieldValue, index) => {
                                            return (
                                                <FormRow key={uuidv4()}>
                                                    <ItemLeft>
                                                        {index === 0 && (
                                                            <>
                                                                <LabelSign />
                                                                <FormLabelWrap>
                                                                    <FormLabel>
                                                                        {field}
                                                                        {fieldList[field] === "required" && " *"}
                                                                    </FormLabel>
                                                                </FormLabelWrap>
                                                            </>
                                                        )}
                                                    </ItemLeft>
                                                    <ItemRight>
                                                        <FormContent>
                                                            <WithDelBtn>
                                                                <FormInput
                                                                    {...register(`${field}[${index}]`, {
                                                                        value: fieldValue,
                                                                        shouldUnregister: true,
                                                                    })}
                                                                    hasError={!!error[field]}
                                                                    onBlur={(e) =>
                                                                        handleUpdate({
                                                                            ...bibJson,
                                                                            [field]: fieldValues
                                                                                .map((v, i) =>
                                                                                    i === index ? e.target.value : v,
                                                                                )
                                                                                .join(" and "),
                                                                        })
                                                                    }
                                                                    onKeyDown={handleOnKeyDown}
                                                                />
                                                                {index === fieldValues.length - 1 && (
                                                                    <Button
                                                                        onlyIcon
                                                                        margin={"0 0 0 8px"}
                                                                        buttonSize={"24px"}
                                                                        bgColor={"transparent"}
                                                                        hoverBgColor={"var(--color-ButtonHover4)"}
                                                                        onClick={(e) => {
                                                                            const newBibJson = { ...bibJson };
                                                                            newBibJson[field] =
                                                                                newBibJson[field] + " and ";
                                                                            handleUpdate(newBibJson);
                                                                        }}
                                                                    >
                                                                        <Icon
                                                                            name={"plus"}
                                                                            size={"12"}
                                                                            color={"var(--color-SubBlack)"}
                                                                        />
                                                                    </Button>
                                                                )}
                                                                {!(
                                                                    fieldList[field] === "required" &&
                                                                    fieldValues.length === 1
                                                                ) && (
                                                                    <Button
                                                                        onlyIcon
                                                                        margin={"0 0 0 8px"}
                                                                        buttonSize={"24px"}
                                                                        bgColor={"transparent"}
                                                                        hoverBgColor={"var(--color-ButtonHover4)"}
                                                                        onClick={(e) => {
                                                                            const newBibJson = { ...bibJson };
                                                                            if (fieldValues.length === 1) {
                                                                                fieldDelete(field);
                                                                                return;
                                                                            }
                                                                            newBibJson[field] = fieldValues
                                                                                .filter((_, i) => i !== index)
                                                                                .join(" and ");
                                                                            handleUpdate(newBibJson);
                                                                            unregister(`${field}[${index}]`);
                                                                        }}
                                                                    >
                                                                        <Icon
                                                                            name={"delete"}
                                                                            size={"12"}
                                                                            color={"var(--color-SubBlack)"}
                                                                        />
                                                                    </Button>
                                                                )}
                                                            </WithDelBtn>
                                                        </FormContent>
                                                    </ItemRight>
                                                </FormRow>
                                            );
                                        })}
                                        {error[field] && <ErrorMessage>{error[field]}</ErrorMessage>}
                                    </div>
                                );
                            }
                            return (
                                <div key={uuidv4()}>
                                    <FormRow>
                                        <ItemLeft>
                                            <LabelSign />
                                            <FormLabelWrap>
                                                <FormLabel>
                                                    {field}
                                                    {fieldList[field] === "required" && " *"}
                                                </FormLabel>
                                            </FormLabelWrap>
                                        </ItemLeft>
                                        <ItemRight>
                                            <FormContent>
                                                <WithDelBtn>
                                                    <FormInput
                                                        {...register(`${field}`, options)}
                                                        hasError={!!error[field]}
                                                        onBlur={(e) =>
                                                            handleUpdate({
                                                                ...bibJson,
                                                                [field]: e.target.value,
                                                            })
                                                        }
                                                        onKeyDown={handleOnKeyDown}
                                                    />
                                                    <Button
                                                        key={uuidv4()}
                                                        onlyIcon
                                                        margin={"0 0 0 8px"}
                                                        buttonSize={"24px"}
                                                        bgColor={"transparent"}
                                                        hoverBgColor={"var(--color-ButtonHover4)"}
                                                        onClick={(e) => fieldDelete(field)}
                                                        style={
                                                            fieldList[field] === "required" && { visibility: "hidden" }
                                                        }
                                                    >
                                                        <Icon
                                                            name={"delete"}
                                                            size={"12"}
                                                            color={"var(--color-SubBlack)"}
                                                        />
                                                    </Button>
                                                </WithDelBtn>
                                            </FormContent>
                                        </ItemRight>
                                    </FormRow>
                                    {error[field] && <ErrorMessage>{error[field]}</ErrorMessage>}
                                </div>
                            );
                        })}
                    </FormInner>
                </FormContainer>
            </TabviewWrap>
            <FieldAddArea>
                {deletedField.sort().map((field) => {
                    return (
                        <Button
                            key={uuidv4()}
                            margin={"0 8px 0 0"}
                            padding={"0 8px"}
                            height={"24px"}
                            width={"auto"}
                            bgColor={"var(--color-Button3)"}
                            hoverBgColor={"var(--color-ButtonHover3)"}
                            fontColor={"var(--color-White)"}
                            buttonText={field}
                            fontSize={"small"}
                            onClick={(e) => {
                                const newBibJson = { ...bibJson };
                                newBibJson[field] = "";
                                handleUpdate(newBibJson);
                            }}
                        >
                            <Icon name={"plus"} size={"12"} color={"var(--color-White)"} />
                        </Button>
                    );
                })}
            </FieldAddArea>
            {showReferenceBibInfoViewModal && (
                <AbstractModal
                    modalTitle="BibTeX"
                    width={"800"}
                    exitModal={(e) => setShowReferenceBibInfoViewModal(!showReferenceBibInfoViewModal)}
                    id={"referenceBibInfoViewModal"}
                >
                    <ReferenceBibInfoViewModal bib_json={bibJson} />
                </AbstractModal>
            )}
        </Container>
    );
}

export default TabReferenceBibInfo;
