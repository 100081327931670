import React from "react";
import { createPortal } from "react-dom";

import styled from "styled-components";

import Icon from "components/atoms/Icon";
import TitleText from "components/atoms/text/TitleText";

import Constants from "utils/constants";

const Components = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: inherit;
    z-index: 100;

    ${Constants.BLUR_OPTION}
`;

const RealBox = styled.div`
    position: inherit;
    box-sizing: border-box;
    margin: 24px;
    width: ${(props) => props.width}px;
    height: auto;
    max-height: calc(100vh - 40px);
    border-radius: 12px;
    border: solid 1px #bac9d0;
    background-color: var(--color-White);
    overflow: initial;
`;

const InnerWrap = styled.div`
    margin: 24px;
    height: 100%;
    max-height: calc(100vh - 80px);
    background-color: var(--color-White);
`;

const ModalHeader = styled.div`
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
`;

const CloseButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    background-color: transparent;
    transition: all 100ms ease-in;
    cursor: pointer;

    &:hover {
        background-color: var(--color-ButtonHover4);
    }
`;

const ContentsWrap = styled.div`
    box-sizing: border-box;
    margin: 24px 0 0 0;
    height: calc(100% - 64px);
`;

function AbstractModal({ modalTitle, width, children, exitModal, id }) {
    return (
        <>
            {createPortal(
                <Components className="modalContainer" id={id}>
                    <RealBox width={width}>
                        <InnerWrap>
                            <ModalHeader>
                                <TitleText size={"subRegular"}>{modalTitle}</TitleText>
                                <CloseButton onClick={exitModal}>
                                    <Icon name={"x"} color={"var(--color-SubBlack)"} />
                                </CloseButton>
                            </ModalHeader>
                            <ContentsWrap>{children}</ContentsWrap>
                        </InnerWrap>
                    </RealBox>
                </Components>,
                document.body,
            )}
        </>
    );
}

export default AbstractModal;
