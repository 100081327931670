import React from "react";

import styled from "styled-components";

import Button from "components/atoms/button/Button";

const Container = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 24px 0 0 0;
    width: 100%;
    height: 32px;
`;

function AbstractModalFooter(props) {
    return (
        <Container>
            {props.onlyLeftBtn ? (
                <Button
                    onlyText
                    onClick={props.leftOnClick}
                    width={"80px"}
                    height={"32px"}
                    bgColor={"var(--color-Button4)"}
                    hoverBgColor={"var(--color-ButtonHover4)"}
                    fontColor={"var(--color-Black)"}
                    fontWeight={"400"}
                    buttonText={props.leftBtnText}
                />
            ) : (
                <>
                    <Button
                        onlyText
                        onClick={props.leftOnClick}
                        width={"80px"}
                        height={"32px"}
                        bgColor={"var(--color-Button4)"}
                        hoverBgColor={"var(--color-ButtonHover4)"}
                        fontColor={"var(--color-Black)"}
                        fontWeight={"400"}
                        buttonText={props.leftBtnText}
                    />
                    <Button
                        onlyText
                        onClick={props.rightOnClick}
                        margin={"0 0 0 4px"}
                        width={"80px"}
                        height={"32px"}
                        bgColor={"var(--color-Button1)"}
                        hoverBgColor={"var(--color-ButtonHover1)"}
                        fontColor={"var(--color-White)"}
                        buttonText={props.rightBtnText}
                        disabled={props.disabled}
                    />
                </>
            )}
        </Container>
    );
}

export default AbstractModalFooter;
