import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";

import GeneralText from "components/atoms/text/GeneralText";

const Container = styled.div`
    position: relative;
    width: ${(props) => props.width || "fit-content"};
    height: fit-content;
    display: inline-block;
`;

const TooltipBox = styled.div`
    visibility: ${(props) => props.visibility};

    top: ${(props) => props.y}px;
    left: ${(props) => props.x}px;
    position: fixed;
    display: -webkit-box;
    padding: 4px 6px;
    width: max-content;
    max-width: 300px;
    max-height: 259px;
    background-color: rgba(11, 33, 44, 0.9);
    border-radius: 4px;
    letter-spacing: -0.25px;
    text-align: center;
    opacity: 1;
    z-index: 100;
    pointer-events: none;
    white-space: normal;
    text-overflow: ellipsis;

    -webkit-line-clamp: 15;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

function Tooltip(props) {
    const { position = "bottomRight", width, children, message } = props;

    const containerRef = useRef(null);
    const tooltipRef = useRef(null);

    const [tooltipConfig, setTooltipConfig] = useState({ visible: false, x: 0, y: 0 });

    const calculateTooltipPosition = () => {
        if (!containerRef.current || !tooltipRef.current) return;

        const rect = containerRef.current.getBoundingClientRect();
        const tooltipHeight = tooltipRef.current.offsetHeight || 0;
        const tooltipWidth = tooltipRef.current.offsetWidth || 0;

        let x = rect.left;
        let y = rect.bottom;

        const space = 4;

        if (position === "top") {
            y = rect.top - tooltipHeight + space;
            x = rect.left + rect.width / 2 - tooltipWidth / 2;
        } else if (position === "left") {
            x = rect.left - tooltipWidth - space;
            y = rect.top + rect.height / 2 - tooltipHeight / 2;
        } else if (position === "right") {
            x = rect.right + space;
            y = rect.top + rect.height / 2 - tooltipHeight / 2;
        } else if (position === "bottom") {
            y = rect.bottom + space;
            x = rect.left + rect.width / 2 - tooltipWidth / 2;
        } else {
            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;

            if (x + tooltipWidth > windowWidth) {
                x = x - tooltipWidth + rect.width;
            }

            if (y + tooltipHeight > windowHeight) {
                y = rect.top - tooltipHeight - space;
            } else {
                y = rect.bottom + space;
            }
        }
        setTooltipConfig({ visible: true, x, y });
    };

    useEffect(() => {
        const container = containerRef.current;

        const handleMouseEnter = () => {
            calculateTooltipPosition();
        };

        const handleMouseLeave = () => {
            setTooltipConfig({ visible: false, x: 0, y: 0 });
        };

        container.addEventListener("mouseenter", handleMouseEnter);
        container.addEventListener("mouseleave", handleMouseLeave);

        return () => {
            container.removeEventListener("mouseenter", handleMouseEnter);
            container.removeEventListener("mouseleave", handleMouseLeave);
        };
    }, []);

    return (
        <Container width={width} ref={containerRef}>
            {children}
            {message && (
                <>
                    <TooltipBox
                        className="tooltip"
                        x={tooltipConfig.x}
                        y={tooltipConfig.y}
                        visibility={tooltipConfig.visible ? "visible" : "hidden"}
                        ref={tooltipRef}
                    >
                        <GeneralText size="small" color="var(--color-White)">
                            {message}
                        </GeneralText>
                    </TooltipBox>
                </>
            )}
        </Container>
    );
}

export default Tooltip;
