import { useRef, useState } from "react";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";

import styled from "styled-components";

import Icon from "components/atoms/Icon";
import Tooltip from "components/atoms/Tooltip";
import Alert from "components/atoms/alert/Alert";
import Button from "components/atoms/button/Button";
import useLoading from "components/atoms/loading/useLoading";
import GeneralText from "components/atoms/text/GeneralText";
import SentenceText from "components/atoms/text/SentenceText";
import TitleText from "components/atoms/text/TitleText";
import useToast from "components/atoms/toast/useToast";

import { postBibliography } from "utils/request/bibliography";
import { toBibJson } from "utils/scienceon";
import { apiUrl } from "utils/urls";

import axios from "axios";

const Container = styled.div`
    width: 100%;
`;

const ItemHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const HeaderLeft = styled.div`
    display: flex;
    align-itmes: center;
    width: calc(100% - 32px);
`;

const RowWrap = styled.div`
    display: flex;
`;

const TitleButton = styled.button`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    background-color: transparent;
    border: transparent;
    outline: none;

    &:hover {
        p {
            text-decoration: underline;
            color: var(--color-Black);
        }
    }
`;

const ToggleSign = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px 0 0 0;
    width: 12px;
    height: 12px;
`;

const InfoWrap = styled.div`
    width: 100%;
    margin: 8px 0 0 0;
`;

const InfoItem = styled.div`
    display: flex;
    align-items: flex-start;
    margin: 0 0 4px 0;
    min-height: 24px;

    &:last-child {
        margin: 0;
    }
`;

const IconWrap = styled.div`
    position: relative;
    top: 3px;
    display: flex;
    align-items: center;
    margin: 0 8px 0 0;
    width: 16px;
    height: 16px;
    transform: scale(0.9);
`;

const PublisherItem = styled.span`
    display: inline-block;
    margin: 0 0 0 8px;

    &:first-child {
        margin: 0;
    }
`;

const AuthorItem = styled.span`
    display: inline-block;
    margin: 0 0 0 8px;

    &:first-child {
        margin: 0;
    }
`;

const KeywordItem = styled.span`
    display: inline-block;
    margin: 0 0 0 8px;

    &:first-child {
        margin: 0;
    }
`;

const DOIWrap = styled.div`
    display: flex;
    align-items: center;

    a {
        margin: 0 4px 0 0;

        &:last-child {
            margin: 0;
        }
    }
`;

const HideSection = styled.div`
    display: ${(props) => props.display};
`;

const AbstractWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 12px 0 8px 0;
`;

const LinkButtonStyle = {
    width: "56px",
    height: "18px",
    borderRadius: "2px",
    bgColor: "var(--color-Button2)",
    hoverBgColor: "var(--color-ButtonHover2)",
    fontSize: "xsmall",
};

function ReferenceDetailRelatedPaperItem(props) {
    const { setToast } = useToast();
    const { setLoading } = useLoading();

    const bibJson = useRef(null);

    const handleQuoteButtonClicked = async (e) => {
        await fetchBibJson();

        // console.log(bibJson.current);
        let bib_json = toBibJson(bibJson.current);
        // console.log(bib_json);

        bibliographyMutation.mutate({
            body: {
                bib_json: bib_json,
                display_name: bib_json.title,
            },
        });
    };

    const [isHideSectionVisible, setIsHideSectionVisible] = useState(false);
    const handleHideSection = async () => {
        if (isHideSectionVisible === false) {
            await fetchBibJson();
        }
        setIsHideSectionVisible(!isHideSectionVisible);
    };

    // 참고 문헌 추가 mutation
    const bibliographyMutation = useMutation(postBibliography, {
        onSuccess: (data, variables, context) => {
            setToast("참고문헌을 등록했습니다.", "info");
        },
    });

    const fetchBibJson = async () => {
        if (bibJson.current === null) {
            try {
                setLoading(true);
                const res = await axios.get(apiUrl("paperDetail"), {
                    params: { paper_id: props.paperInfo.scienceon_cn },
                });
                setLoading(false);

                // console.log(res.data);
                bibJson.current = res.data;
            } catch (error) {
                setLoading(false);
                Alert("warn", "에러", "상세정보를 불러오지 못했습니다.");
                console.log(error);
            }
        }
    };

    return (
        <Container>
            <ItemHeader>
                <HeaderLeft>
                    <RowWrap>
                        <TitleButton onClick={handleHideSection}>
                            <GeneralText margin={"2px 4px 0 0"} size={"regular"} color={"var(--color-SubBlack)"}>
                                ({props.paperInfo.pub_year}) {props.paperInfo.title}
                            </GeneralText>
                            <ToggleSign>
                                <Icon
                                    name={isHideSectionVisible ? "arrowUp" : "arrowDown"}
                                    color={"var(--color-SubBlack)"}
                                />
                            </ToggleSign>
                        </TitleButton>
                    </RowWrap>
                </HeaderLeft>
                <Tooltip message={"참고문헌에 추가"}>
                    <Button
                        onlyIcon
                        bgColor={"transparent"}
                        hoverBgColor={"var(--color-ButtonHover4)"}
                        onClick={handleQuoteButtonClicked}
                    >
                        <Icon name={"quote"} size={"8"} color={"var(--color-SubBlack)"} />
                    </Button>
                </Tooltip>
            </ItemHeader>
            <HideSection display={isHideSectionVisible ? "initial" : "none"}>
                <InfoWrap>
                    {/* 퍼블리셔 */}
                    <InfoItem>
                        <IconWrap>
                            <Icon name={"publisher"} size={"16"} color={"var(--color-Grey2)"} />
                        </IconWrap>
                        <GeneralText size={"small"} margin={"3px 0 0 0"}>
                            <PublisherItem>{bibJson.current?.journal}</PublisherItem>
                        </GeneralText>
                    </InfoItem>

                    {/* 저자 */}
                    <InfoItem>
                        <IconWrap>
                            <Icon name={"user"} size={"16"} color={"var(--color-Grey2)"} />
                        </IconWrap>
                        <GeneralText size={"small"} margin={"3px 0 0 0"}>
                            <AuthorItem>{bibJson.current?.author || "저자가 없습니다."}</AuthorItem>
                        </GeneralText>
                    </InfoItem>
                </InfoWrap>
                <AbstractWrap>
                    <TitleText withBarType size={"subSmall"} color={"var(--color-SubBlack)"}>
                        초록
                    </TitleText>
                    {bibJson.current?.abstract && bibJson.current.abstract.length > 0 ? (
                        <SentenceText size={"regular"} margin={"8px 0 0 8px"} textAlign={"justify"}>
                            {bibJson.current.abstract}
                        </SentenceText>
                    ) : (
                        <SentenceText size={"regular"} margin={"8px 0 0 8px"}>
                            미리 볼 수 있는 초록이 없습니다.
                        </SentenceText>
                    )}
                </AbstractWrap>

                {/* 키워드 */}
                <InfoItem>
                    <IconWrap>
                        <Icon name={"tag"} size={"16"} color={"var(--color-Grey2)"} />
                    </IconWrap>
                    <GeneralText size={"small"} margin={"3px 0 0 0"}>
                        <KeywordItem>{bibJson.current?.keyword || "키워드가 없습니다."}</KeywordItem>
                    </GeneralText>
                </InfoItem>

                {/* DOI */}
                <InfoItem style={{ alignItems: "center" }}>
                    <IconWrap style={{ top: "0px" }}>
                        <Icon name={"link"} size={"16"} color={"var(--color-Grey2)"} />
                    </IconWrap>
                    <DOIWrap>
                        {bibJson.current?.doi && (
                            <Link to={bibJson.doi} target={"_blank"}>
                                <Button onlyText {...LinkButtonStyle} buttonText={"DOI"} />
                            </Link>
                        )}
                        <Link
                            to={"https://scholar.google.com/scholar?q=" + encodeURIComponent(bibJson.current?.title)}
                            target={"_blank"}
                        >
                            <Button onlyText {...LinkButtonStyle} buttonText={"Google"} />
                        </Link>
                        {bibJson.current?.scienceon_content_url && (
                            <Link to={bibJson.current?.scienceon_content_url} target={"_blank"}>
                                <Button onlyText {...LinkButtonStyle} buttonText={"ScienceON"} />
                            </Link>
                        )}
                        {bibJson.current?.scienceon_fulltext_url && (
                            <Link to={bibJson.current?.scienceon_fulltext_url} target={"_blank"}>
                                <Button onlyText {...LinkButtonStyle} buttonText={"PDF"} />
                            </Link>
                        )}
                    </DOIWrap>
                </InfoItem>
            </HideSection>
        </Container>
    );
}

export default ReferenceDetailRelatedPaperItem;
